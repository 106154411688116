import { gapi, loadAuth2 } from 'gapi-script';
import config from '../config.js'
import AWS from 'aws-sdk';
import {
    CognitoUserPool,
    AuthenticationDetails,
    CognitoUser
} from 'amazon-cognito-identity-js';
import { SET_CURRENT_USER } from './types';
import apiClient from './api-client'
import ClevertapReact from 'clevertap-react';
import * as jwt_decode from "jwt-decode";

export function setCurrentUser(user) {
    ClevertapReact.profile(user);
    return {
        type: SET_CURRENT_USER,
        user
    }
}


export function userLogoutRequest() {

    ClevertapReact.logout();
    // return dispatch => {
        return new Promise((resolve, reject) => {
            const userObj = JSON.parse(localStorage.getItem('userObj'));
            localStorage.removeItem('token');
            localStorage.removeItem('accessKeyId');
            localStorage.removeItem('secretAccessKey');
            localStorage.removeItem('sessionToken');
            localStorage.removeItem('accessToken');
            localStorage.removeItem('identityToken');
            localStorage.removeItem('userObj')
            localStorage.removeItem('theme')
            if (localStorage.getItem('googleLoginInfo')) {
                localStorage.removeItem('googleLoginInfo')
            }
            if (localStorage.getItem('lpLoginInfo')) {
                localStorage.removeItem('lpLoginInfo')
            }
            // dispatch(setCurrentUser());
            if (userObj && userObj.federatedIdentity) {
                // window.gapi.load('auth2', () => {
                //     var auth2 = gapi.auth2.init({
                //         client_id: config.googleClientId,
                //     })
                //     console.log("logging out")
                //     auth2.then(() => {
                //         auth2.signOut().then(function () {
                //             auth2.disconnect();
                //             resolve(localStorage.removeItem('userObj'));
                //         });
                //     });
                // });
                loadAuth2(gapi, config.googleClientId, config.googleClassRoomScopes).then((auth2) => {
                    auth2.signOut().then(
                        resolve("Logged out")
                    )
                }).catch((err) => {
                    console.log(err);
                });

            }
            resolve("Logged out")
        });
    // }
}


export function getIdentityProfile(userName) {

    return dispatch => {

        return new Promise((resolve, reject) => {

            var pathTemplate = '/user/{userName}';
            var method = 'GET';
            var additionalParams = { 'accessToken': localStorage.getItem('access_token') };
            var additionalParams = {};
            var body = {};

            var params = {
                userName: userName,
            };

            apiClient.invokeApi(params, pathTemplate, method, additionalParams, body)
                .then(function (result) {
                    result.data.multiLingualLicense == false && (result.data.prefLanguage = 'en');
                    result.data.federatedIdentity = true;
                    localStorage.setItem('userObj', JSON.stringify(result.data));
                    dispatch(setCurrentUser(result.data));
                    resolve(result.data.userType.toLowerCase() === "student" ? "/student/dashboard" : "/dashboard");
                })
                .catch(function (err) {
                    reject(err);
                });

        })
    }

}


export function processRequestToCognito(response, route) {


    return dispatch => {

        const token = response['id_token'];
        const access_token = response['access_token'];
        var decoded = jwt_decode(token);
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId: config.identityPoolId,
            Logins: {
                'accounts.google.com': token
            }
        });
        AWS.config.credentials.clearCachedId();
        AWS.config.region = config.region;
        return new Promise((resolve, reject) => {
            AWS.config.credentials.clearCachedId();
            AWS.config.credentials.get(function (err, data) {
                if (err) {
                    err.message = "Authentication error with cognito"
                    reject(err.message);
                }

                localStorage.setItem('accessToken', access_token);
                localStorage.setItem('identityToken', token);
                localStorage.setItem('accessKeyId', AWS.config.credentials.accessKeyId);
                localStorage.setItem('secretAccessKey', AWS.config.credentials.secretAccessKey);
                localStorage.setItem('sessionToken', AWS.config.credentials.sessionToken);

                let newUser = {
                    userName: decoded.sub,
                    firstName: response.firstName,
                    lastName: response.lastName,
                    email: response.email
                }

                var params = {
                    userName: newUser.userName,
                };

                var pathTemplate = '/user/{userName}?email=' + response.email;
                var method = 'GET';
                var additionalParams = { 'accessToken': localStorage.getItem('access_token') };
                var additionalParams = {};
                var body = {};

                apiClient.invokeApi(params, pathTemplate, method, additionalParams, body)
                    .then(function (result) {
                        result.data.multiLingualLicense == false && (result.data.prefLanguage = 'en');
                        result.data.federatedIdentity = true;
                        localStorage.setItem('userObj', JSON.stringify(result.data));
                        dispatch(setCurrentUser(result.data));
                        if (route) {
                            resolve(route)
                        } else {
                            resolve(result.data.userType.toLowerCase() === "student" ? "/student/dashboard" : "/dashboard");
                        }
                    })
                    .catch(function (err) {
                        if (err.response.status == 400) {
                            newUser.googleSignUp = true;
                            dispatch(setCurrentUser(newUser));
                            reject("/signup");
                        }
                        reject(err);
                    });
            });
        })
    }
}

export function userLoginRequest(userData) {

    ClevertapReact.event("Login", userData);
    return dispatch => {
        var userPool = new CognitoUserPool({
            UserPoolId: config.userPoolId,
            ClientId: config.clientId
        });
        var authenticationData = {
            Username: userData.username.toLowerCase().trim(),
            Password: userData.password
        };

        var user = new CognitoUser({ Username: userData.username.toLowerCase().trim(), Pool: userPool });
        var authenticationDetails = new AuthenticationDetails(authenticationData);
        return new Promise((resolve, reject) => (
            user.authenticateUser(authenticationDetails, {
                onSuccess: (result) => {

                    var params = {
                        IdentityPoolId: config.identityPoolId,
                        Logins: {
                            // Change the key below according to the specific region your user pool is in.
                            [config.cognitoURL]: result.getIdToken().getJwtToken()
                        }
                    };
                    AWS.config.region = config.region;
                    AWS.config.credentials = new AWS.CognitoIdentityCredentials(params);
                    AWS.config.credentials.clearCachedId();
                    AWS.config.credentials.get(function (err) {
                        if (err) {
                            console.log("Error: " + err);
                            reject(err);
                        }
                        localStorage.setItem('token', result.getIdToken().getJwtToken());
                        localStorage.setItem('accessKeyId', AWS.config.credentials.accessKeyId);
                        localStorage.setItem('secretAccessKey', AWS.config.credentials.secretAccessKey);
                        localStorage.setItem('sessionToken', AWS.config.credentials.sessionToken);

                        var params = {
                            userName: userData.username.toLowerCase().trim(),
                        };
                        var pathTemplate = '/user/{userName}';
                        var method = 'GET';
                        var additionalParams = {};
                        var body = {};

                        apiClient.invokeApi(params, pathTemplate, method, additionalParams, body)
                            .then(function (result) {
                                let newUserPool = new CognitoUserPool({
                                    UserPoolId: config.userPoolId,
                                    ClientId: config.clientId
                                });
                                let cognitoUser = newUserPool.getCurrentUser();
                                cognitoUser.getSession(function (err, session) {
                                    if (session) {
                                        cognitoUser.getUserAttributes(function (err, result2) {
                                            if (err) {

                                            }
                                            else {

                                                let index = result2.findIndex(x => x.Name === 'email_verified');
                                                if (index !== -1) {
                                                    if (result2[index].Value === "true") {
                                                        result.data.emailVerified = true;
                                                    }
                                                    else {
                                                        result.data.emailVerified = false;
                                                    }
                                                }
                                                else {
                                                    result.data.emailVerified = false;
                                                }
                                            }
                                            result.data.multiLingualLicense == false && (result.data.prefLanguage = 'en');
                                            localStorage.setItem('userObj', JSON.stringify(result.data));
                                            dispatch(setCurrentUser(result.data));
                                            resolve(result.data.userType.toLowerCase() === "student" ? "/student/dashboard" : "/dashboard");
                                        });
                                    }
                                });
                            }).catch(function (result) {
                                // result.response.data.errors[0].message
                                // reject(result.response.data.errors[0]);
                                reject(result);
                                //This is where you would put an error callback
                            });
                    });

                },
                onFailure: (err) => {
                    reject(err)
                },
            })
        ));

    }
}

export function loginLinkedAccount() {

    // return dispatch => {

        return new Promise((resolve, reject) => {
            const userObj = JSON.parse(localStorage.getItem('userObj'));

            if (userObj.federatedIdentity) {

                if (!localStorage.getItem('googleLoginInfo')) {

                    let googleLoginInfo = {};
                    googleLoginInfo.accessKeyId = localStorage.getItem('accessKeyId');
                    googleLoginInfo.secretAccessKey = localStorage.getItem('secretAccessKey');
                    googleLoginInfo.sessionToken = localStorage.getItem('sessionToken');
                    googleLoginInfo.accessToken = localStorage.getItem('accessToken');
                    googleLoginInfo.identityToken = localStorage.getItem('identityToken');
                    googleLoginInfo.userObj = localStorage.getItem('userObj');
                    localStorage.setItem('googleLoginInfo', JSON.stringify(googleLoginInfo));
                }


                if (localStorage.getItem('lpLoginInfo')) {


                    localStorage.removeItem('accessKeyId');
                    localStorage.removeItem('secretAccessKey');
                    localStorage.removeItem('sessionToken');
                    localStorage.removeItem('accessToken');
                    localStorage.removeItem('identityToken');
                    localStorage.removeItem('userObj')

                    let learnerPal = JSON.parse(localStorage.getItem('lpLoginInfo'));
                    localStorage.setItem('token', learnerPal.token);
                    localStorage.setItem('accessKeyId', learnerPal.accessKeyId);
                    localStorage.setItem('secretAccessKey', learnerPal.secretAccessKey);
                    localStorage.setItem('sessionToken', learnerPal.sessionToken);
                    localStorage.setItem('userObj', learnerPal.userObj);

                } else {

                    localStorage.removeItem('token');
                    localStorage.removeItem('accessKeyId');
                    localStorage.removeItem('secretAccessKey');
                    localStorage.removeItem('sessionToken');
                    localStorage.removeItem('accessToken');
                    localStorage.removeItem('identityToken');
                    localStorage.removeItem('userObj')
                    if (localStorage.getItem('accessToken')) {
                        resolve('/');
                    }
                }
                resolve(userObj.userType.toLowerCase() === "student" ? "/student/dashboard" : "/dashboard");

            } else {

                if (!localStorage.getItem('lpLoginInfo')) {

                    let lpLoginInfo = {};
                    lpLoginInfo.token = localStorage.getItem('token');
                    lpLoginInfo.accessKeyId = localStorage.getItem('accessKeyId');
                    lpLoginInfo.secretAccessKey = localStorage.getItem('secretAccessKey');
                    lpLoginInfo.sessionToken = localStorage.getItem('sessionToken');
                    lpLoginInfo.userObj = localStorage.getItem('userObj');

                    localStorage.setItem('lpLoginInfo', JSON.stringify(lpLoginInfo));
                }


                if (localStorage.getItem('googleLoginInfo')) {

                    localStorage.removeItem('token');
                    localStorage.removeItem('accessKeyId');
                    localStorage.removeItem('secretAccessKey');
                    localStorage.removeItem('sessionToken');
                    localStorage.removeItem('userObj')

                    let Google = JSON.parse(localStorage.getItem('googleLoginInfo'));
                    localStorage.setItem('token', Google.token);
                    localStorage.setItem('accessKeyId', Google.accessKeyId);
                    localStorage.setItem('secretAccessKey', Google.secretAccessKey);
                    localStorage.setItem('sessionToken', Google.sessionToken);
                    localStorage.setItem('accessToken', Google.accessToken);
                    localStorage.setItem('identityToken', Google.identityToken);
                    localStorage.setItem('userObj', Google.userObj);

                } else {

                    localStorage.removeItem('token');
                    localStorage.removeItem('accessKeyId');
                    localStorage.removeItem('secretAccessKey');
                    localStorage.removeItem('sessionToken');
                    localStorage.removeItem('userObj');
                }
                resolve(userObj.userType.toLowerCase() === "student" ? "/student/dashboard" : "/dashboard");
            }
        })
    // }
}



export default { setCurrentUser, userLoginRequest, processRequestToCognito, getIdentityProfile, loginLinkedAccount };
