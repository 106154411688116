
/**
 * Created by ashahab on 7/21/2017.
 */
import React from "react";
import Heading from "../heading/heading";
import { browserHistory } from "react-router";
import { connect } from "react-redux";
import {
    CREATE_QUESTION,
    MODIFY_QUESTION,
    RECEIVED_QUESTIONS_RESULTS,
    ElasticSearchTypes,
    ElasticSearchColumns,
    CLONE_QUESTION,
    SET_CURRENT_USER,
    RECEIVED_USERS_RESULTS
} from "../../actions/types";
import MaintenanceTable from "../../components/maintenance-table/maintenance-table";
import Filter from "../../components/filter/filter";
import LoginUtils from "../common/login-utils";
import ManageQuestionsActions from "../../actions/manage-questions-actions";
import Breadcrumb from "../breadcrumb/breadcrumb";
import { Alert, Button, ControlLabel, FormControl, FormGroup, HelpBlock, Modal } from "react-bootstrap";
import config from "../../config";
import ProgressIndicator from "../progress-indicator/progress-indicator";
import { RadioButton } from "../radiobutton/radio-button";
import $ from "jquery";
import apiClient from "../../actions/api-client";
import QuestionsMaintenanceTableMobile from "../question-maintenance-table-mobile/question-maintenace-table-mobile";
import { Parser } from "html-to-react";
import ElasticSearchQuery from "../../actions/elastic-search-query";
import ElasticSearchActions from "../../actions/elastic-search-actions";
import ReactScrollPagination from 'react-scroll-pagination'
import Rater from 'react-rater'
import cominSoon from "./comingSoon.png";
import MaterialIcon from "react-google-material-icons";
import SelectFieldGroup from "../forms/select-field-group";
import AutoCompleteGroup from "../forms/auto-complete-group";
import TextFieldGroup from "../forms/text-field-group";
import icon1 from '../../assets/images/icon1.png';
import cnq from '../../assets/images/cnq.png';
import mq from '../../assets/images/mq.png';
import mcqs from '../../assets/images/mcqs.png';
import essayqs from '../../assets/images/essayqs.png';
import matching from '../../assets/images/matching.png';
import truefalse from '../../assets/images/truefalse.png';
import response from '../../assets/images/response.png';
import importbutton from '../../assets/images/importbutton.png';
import minusbutton from '../../assets/images/minusbutton.png';
import logo from '../../assets/images/check_file1600.png'
import XLSX from 'xlsx';
import importIcon from "./import.png";
import importIconHover from "./import-hvr.png";
import exportIcon from "./export.png";
import exportIconHover from "./export-hvr.png";
import './style.css';
import { debug } from "util";
// import axios from "axios";
import NewQuestion from "./new-question"
import EditQuestion from "./edit-question"
import customSubject from "./customSubject.png";
import MoveQuestionsComponent from './move-questions-control';
import questionMove from "./questionMove.png";
import './custom.css';
import './checkbox-fix.css'

class ManageQuestions extends React.Component {

    constructor(props) {
        super(props);
        this.viewQuestion = this.viewQuestion.bind(this);
        this.hideViewQuestionPage = this.hideViewQuestionPage.bind(this);
        this.hideViewDetailPage = this.hideViewDetailPage.bind(this);
        this.showViewDetailsPage = this.showViewDetailsPage.bind(this);
        this.getNextPage = this.getNextPage.bind(this);
        this.viewQuestionOnRowClick = this.viewQuestionOnRowClick.bind(this);
        this.getHintDialog = this.getHintDialog.bind(this);
        this.createQuestion = this.createQuestion.bind(this);
        this.deleteQuestion = this.deleteQuestion.bind(this);
        this.exportQuestionsInExcel = this.exportQuestionsInExcel.bind(this);
        this.importExcelFile = this.importExcelFile.bind(this);
        this.getImportDialog = this.getImportDialog.bind(this);
        this.propertyChangedHandler = this.propertyChangedHandler.bind(this);
        this.propertyChangedHandler1 = this.propertyChangedHandler1.bind(this);

        this.handleAlertDismiss = this.handleAlertDismiss.bind(this)

        this.getAllSubjects = this.getAllSubjects.bind(this);
        this.requestElasticSearch = this.requestElasticSearch.bind(this);

        this.getQuestionFilters = this.getQuestionFilters.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onSubmitUpdate = this.onSubmitUpdate.bind(this);

        this.AddButtonForQuestionType = this.AddButtonForQuestionType.bind(this);
        this.AddMoreRecords = this.AddMoreRecords.bind(this);
        this.choiceButton = this.choiceButton.bind(this);
        this.removeRecords = this.removeRecords.bind(this);

        this.dialogueBoxClosingEditor = this.dialogueBoxClosingEditor.bind(this);
        this.dialogueBoxCreateQuestion = this.dialogueBoxCreateQuestion.bind(this);
        this.UpdateQuestiondialogueBox = this.UpdateQuestiondialogueBox.bind(this);

        this.requestAllQuestions = this.requestAllQuestions.bind(this);
        this.customizedSubjectpopup = this.customizedSubjectpopup.bind(this);
        this.updatePopup = this.updatePopup.bind(this);

        this.boolValue = false;


        this.state = {
            screenWidth: window.innerWidth,
            viewQuestionDetailsPage: false,
            selectedRecord: {},
            viewQuestionPanel: false,
            selectedRow: {},
            alertText: "This is a sample alert",
            alertStyle: "danger",
            showAlert: false,
            loadingNextPage: 0,
            showHintDialog: false,
            showCreateQuestionDialogBox: false,
            showQuestionDeleteDialog: false,
            showModifyQuestionDialogBox: false,
            showDuplicateQuestionDialogBox: false,
            questionsLst: null,
            showImportQuestionDialog: false,
            showBeforeImportQuestionDialog: false,
            importFileTypeError: false,
            importFileFormatError: false,
            importFileFormatChoicesError: false,
            showQuestionStateChangedDialog: false,
            selectedSubject: "",
            selectedCategory: "",
            selectedSubCategory: "",

            selectedQuestionType: "",
            error: {},
            allSubjects: [],
            allCategories: [],
            allSubCategories: [],
            isLandingPage: true,
            isActivityTypeLandingPage: false,
            isLandingPage: true,

            addMoreHtmlArray: [],
            addMoreHtmlArray1: [],
            showQuestionChoices: false,
            showQuestionStateChange: false,

            button: 0,
            allQuestionTypes: [
                {
                    "id": "Multiple Choice",
                    "name": "Multiple Choice"
                },
                {
                    "id": "essay",
                    "name": "Essay"

                },
            ],
            questionContent: "",
            customSubject: "",
            schoolType: "",
            selectedSchoolType: [],
            customCategory: "",
            customSubCategory: "",
            errors: {},
            customSubjects: [],
            information: { showInformationPopup: false },
            question: { showQuestionPopup: false }
        };


        this.viewActivityCssClassName = "activityLogBlock questionActivityMainBox";
        this.tableCssClassName = 'listViewBlock ';
        this.filterComponentCssClassName = 'filterBlock NiceScroll';

    }

    getNextPage() {

        const { screenWidth } = this.state;
        const isMobile = screenWidth <= 767;

        if (isMobile && this.state.loadingNextPage === 0) {
            let myID = Math.random() * 10000000000000000000;
            this.setState({ 'loadingNextPage': myID });
            if (this.state.loadingNextPage === myID) {
                let esAttr = this.props.elasticSearchAttributes.map.get('questions');
                const { dispatch } = this.props;
                ElasticSearchActions.setElasticSearchAttributes(dispatch, 'questions', esAttr.searchTerm, esAttr.pageSize + 10, esAttr.activePage, esAttr.sortObj, esAttr.filterValues);
                let elasticSearchQuery = new ElasticSearchQuery();
                elasticSearchQuery.query(ElasticSearchTypes.Question + config.stage, ElasticSearchColumns.Question, this.props,
                    esAttr.searchTerm, esAttr.pageSize + 10, esAttr.activePage, { createdAt: { order: "desc" } }, esAttr.filterValues, function (results, totalRecords) {
                        dispatch({
                            type: RECEIVED_QUESTIONS_RESULTS,
                            status: 200,
                            data: JSON.parse(JSON.stringify(results)),
                            alertText: '',
                            alertStyle: '',
                            showAlert: false,
                            totalRecords: totalRecords
                        });

                    });

                this.setState({ 'loadingNextPage': 0 });
            }

        }

    }

    componentDidMount() {
        LoginUtils.isAuthenticated(this.props, browserHistory, '/questions', '/');
        const { dispatch } = this.props;
        if (!this.props.manageQuestions.alertText) {

            const { dispatch } = this.props;
            // ElasticSearchActions.setElasticSearchAttributes(dispatch, 'questions', '', 10, 1, { createdAt: { order: "desc" } }, []);

            ElasticSearchActions.setElasticSearchAttributes(dispatch, 'questions', '', 200, 1, { createdAt: { order: "desc" } }, []);


            let elasticSearchQuery = new ElasticSearchQuery();
            elasticSearchQuery.query(ElasticSearchTypes.Users + config.stage, ElasticSearchColumns.Users, this.props,
                '', 200, 1, { createdAt: { order: "desc" } }, [], function (results, totalRecords) {
                    dispatch({
                        type: RECEIVED_USERS_RESULTS,
                        status: 200,
                        data: JSON.parse(JSON.stringify(results)),
                        alertText: '',
                        alertStyle: '',
                        showAlert: false,
                        totalRecords: totalRecords
                    });
                }, true);

            this.getAllSubjects();
            this.setState({
                'alertText': this.props.manageQuestions.alertText,
                'alertStyle': this.props.manageQuestions.alertStyle,
                'showAlert': this.props.manageQuestions.showAlert
            });
            setTimeout(() => {
                this.setState({showAlert: false });
            }, 5000);

        }


        else {
            const { dispatch } = this.props;
            ElasticSearchActions.setElasticSearchAttributes(dispatch, 'allUsers', '', 200, 1, { createdAt: { order: "desc" } }, []);
            let elasticSearchQuery = new ElasticSearchQuery();
            elasticSearchQuery.query(ElasticSearchTypes.Users + config.stage, ElasticSearchColumns.Users, this.props,
                '', 200, 1, { createdAt: { order: "desc" } }, [], function (results, totalRecords) {
                    dispatch({
                        type: RECEIVED_USERS_RESULTS,
                        status: 200,
                        data: JSON.parse(JSON.stringify(results)),
                        alertText: '',
                        alertStyle: '',
                        showAlert: false,
                        totalRecords: totalRecords
                    });

                }, false);

            this.getAllSubjects();
            this.setState({
                'alertText': this.props.manageQuestions.alertText,
                'alertStyle': this.props.manageQuestions.alertStyle,
                'showAlert': this.props.manageQuestions.showAlert
            });
            setTimeout(() => {
                this.setState({ ...this.state, showAlert: false });
            }, 5000);
        }
        $('#pageSizeDropDown').val(200);

        this.AddMoreRecords();
        let isLPAdmin = this.props.auth.user.schoolName === "LearnerPal" && this.props.auth.user.userType.toLowerCase() === "admin";
        if(isLPAdmin) {
            this.requestAllQuestions()
        }

        // setInterval(() => {this.setState({
        //     showAlert: true,
        //     alertStyle: 'success',
        //     alertText: "css issues"
        // })}, 1000)


    }

    handleAlertDismiss(e) {
        this.setState({ 'showAlert': false });
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        this.setState({ screenWidth: window.innerWidth });
    };

    showIndicator() {
        this.setState({
            ...this.state,
            loading: true
        });
    }

    hideIndicator() {
        this.setState({
            ...this.state,
            loading: false
        });
    }

    viewQuestion(selectedRecord) {
        let that = this;
        this.setState({ selectedRow: {}, viewQuestionPanel: false }, function () {
            that.showIndicator();
            that.viewActivityCssClassName = "activityLogBlock DivShow QuestionView";
            that.tableCssClassName = 'listViewBlock pulLeftClass QuestionTable';
            that.filterComponentCssClassName = 'filterBlock NiceScroll collapsableDiv';
            apiClient.invokeApi({ questionID: selectedRecord.id }, '/question/{questionID}', 'GET').then(function (result) {
                that.setState({ viewQuestionPanel: true, selectedRecord: result.data, selectedRow: result.data });
                that.hideIndicator();
            }).catch(function (result) {

                console.log(result);
                that.hideIndicator();

            });
        });
    }

    viewQuestionOnRowClick(selectedRecord, event) {
        const { screenWidth } = this.state;
        const isTab = screenWidth <= 1199;
        if (isTab) {

            this.viewQuestion(selectedRecord);
        }
    }

    getHintDialog() {

        let body = <div>
            <div className="infoIcon" style={{ display: 'inline', paddingRight: '10px' }}>
                <img src={cominSoon} style={{ height: '80px' }} />
            </div>
            <div style={{ display: 'inline-block', width: '80%' }}>
                Coming Soon... Question Editor
            </div>
        </div>;

        return <Modal id="hint_modal"
            show={this.state.showHintDialog}
            onHide={() => {
                this.setState({ showHintDialog: false });
            }}
            container={this}
            aria-labelledby="contained-modal-title">
            <Modal.Header closeButton>
                <Modal.Title id="delete-activity-modal">Coming Soon</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {body}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => {
                    this.setState({ showHintDialog: false });
                }} bsStyle='primary'>OK</Button>
            </Modal.Footer>
        </Modal>;
    }
    showViewDetailsPage() {
        let selector = '#' + 'tableFiltersAndSideView';
        $(selector).addClass('removeDisplay');
        this.setState({ viewQuestionDetailsPage: true });
    }

    hideViewDetailPage() {
        this.setState({ viewQuestionDetailsPage: false });
        let selector = '#' + 'tableFiltersAndSideView';
        $(selector).removeClass('removeDisplay');
    }

    hideViewQuestionPage() {
        this.viewActivityCssClassName = "activityLogBlock questionActivityMainBox";
        this.tableCssClassName = 'listViewBlock';
        this.filterComponentCssClassName = 'filterBlock NiceScroll';

        this.setState({ selectedRow: {}, viewQuestionPanel: false });
        let selector = '#' + 'tableFiltersAndSideView';
        $(selector).removeClass('removeDisplay');
    }

    createQuestion() {
        //browserHistory.push('/questions/new');
        if (!this.state.selectedQuestionType) {
            let error = {};
            error.questionType = "Question type is required.";
            error.title = "Question Type";
            this.setState({ error: error });

            return;
        }

        // this.setState({showCreateQuestionDialogBox: true});
    }

    getImportDialog() {

        // let text = "";
        // let count =1;
        // for(var question of this.state.questionsLst){
        //     text+= "<br>" + count++ + ": " + question.question.substring(0, 70).toString() + "...";
        // }

        let htmlToReactParser = new Parser();
        let body = <div id="importDialogContent">


            <h4>Please confirm if you want to upload {this.state.createQuestionsLst.length} question(s)? </h4>
            {this.state.updateQuestionsLst.length > 0 ? <h4>and update {this.state.updateQuestionsLst.length} question(s)? </h4> : ''}

            <p>
                {/* {htmlToReactParser.parse(text)} */}
            </p>
            {/* {this.state.deleteError ? <Alert bsStyle="danger">
             {this.state.deleteErrorMessage}
             </Alert> : ''} */}
        </div>;
        let that = this;
        return <Modal
            backdrop="static"
            show={this.state.showImportQuestionDialog}
            onHide={() => {
                this.setState({
                    showImportQuestionDialog: false,
                    createQuestionsLst: null,
                    updateQuestionsLst: null,
                    deleteError: false
                });

            }}
            container={this}
            aria-labelledby="contained-modal-title">
            <Modal.Header closeButton>
                <Modal.Title id="delete-activity-modal">Upload Question(s)</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {body}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => {

                    for (let subject of that.state.importSubjects) {

                        that.updateSubjectsTable(subject, true);

                        that.updateSubjectInUserProfile(subject);

                    }

                    apiClient.invokeApi({}, '/question', 'POST', {}, that.state.createQuestionsLst).then(function (result) {
                        that.setState({
                            showAlert: true,
                            alertStyle: 'success',
                            alertText: result.data
                        });

                        setTimeout(() => {
                            that.setState({ showAlert: false });
                        }, 3000);

                        // step3: after uploading the question, navigate to the mannage-questions page.
                        //browserHistory.push('/questions');
                    }).catch(function (error) {
                        // console.log("Error occured posting question " + questionData)
                    });

                    that.state.updateQuestionsLst.forEach(element => {
                        apiClient.invokeApi({}, '/question/update', 'put', {}, element, "4").then(function (result) {

                            // "question/update" is also avaliable

                            that.setState({
                                showAlert: true,
                                alertStyle: 'success',
                                alertText: result.data.message
                            });

                            setTimeout(() => {
                                that.setState({ showAlert: false });
                            }, 3000);

                            // step3: after uploading the question, navigate to the mannage-questions page.
                            //browserHistory.push('/questions');
                        }).catch(function (error) {
                            console.log("Error occured posting question" + ", Error " + error)
                        });

                    });

                    that.setState({
                        showImportQuestionDialog: false,
                        createQuestionsLst: null,
                        updateQuestionsLst: null,
                        showBeforeImportQuestionDialog: false,
                        importFileTypeError: false,
                        importFileFormatError: false
                    });


                }}>Yes</Button>
                <Button onClick={() => {
                    that.setState({
                        showImportQuestionDialog: false,
                        createQuestionsLst: null,
                        updateQuestionsLst: null
                    });


                }} bsStyle='primary'>No</Button>
            </Modal.Footer>
        </Modal>;
    }

    getBeforeImportDialog() {

        // let text = "";
        // let count =1;
        // for(var question of this.state.questionsLst){
        //     text+= "<br>" + count++ + ": " + question.question.substring(0, 70).toString() + "...";
        // }

        let isLPAdmin = this.props.auth.user.schoolName === "LearnerPal" && this.props.auth.user.userType.toLowerCase() === "admin";

        let htmlToReactParser = new Parser();
        let body = <div id="importDialogContent">


            <p>Download the question template below and simply upload the template after you have created your questions within the template</p>
            {this.state.importFileTypeError ? <span className='text-danger'>Invalid file. Please upload the template again.</span> : null}
            {this.state.importFileFormatError ? <span className='text-danger'>Invalid format. Please upload the template again.</span> : null}
            {this.state.importFileFormatChoicesError ? <span className='text-danger'>Invalid Choices format. Please upload the template again and specify a digit value for the correct answer.</span> : null}

            {/* <p> */}
            {/* {htmlToReactParser.parse(text)} */}
            {/* </p> */}
            {/* {this.state.deleteError ? <Alert bsStyle="danger">
             {this.state.deleteErrorMessage}
             </Alert> : ''} */}
        </div>;

        let bottomNote = <div style={{
            textAlign: "center",
            padding: "15px",
            backgroundColor: "#d5d5d5",
            color: "#0f6fb4",
            marginTop: "10px",
            "border-bottom-left-radius": "5px",
            "border-bottom-right-radius": "5px",
            //fontSize: {
            //    value: '13px',
            //    important: 'true'
            //}
            //}}
            //ref={(el) => {
            //    if(el) {
            //        el.style.setProperty('font-size', '13px', 'important')
            //    }
        }}
        >Note: All fields in template marked in blue are mandatory</div>;

        let that = this;
        return <Modal
            backdrop="static"
            show={this.state.showBeforeImportQuestionDialog}
            onHide={() => {
                this.setState({
                    showBeforeImportQuestionDialog: false,
                    importFileTypeError: false,
                    importFileFormatError: false,
                    importFileFormatChoicesError: false
                });

            }}
            container={this}
            aria-labelledby="contained-modal-title">
            <Modal.Header closeButton>
                <Modal.Title id="delete-activity-modal">Upload Questions</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {body}
            </Modal.Body>
            <Modal.Footer>

                <Button onClick={() => {

                    this.importExcelFile();

                    // that.setState({
                    //     showBeforeImportQuestionDialog: false,
                    // });

                }}>Upload File</Button>

                &nbsp;

                {isLPAdmin ?

                    <a href="https://s3.amazonaws.com/learnerpal-data/LP/Template.xlsx" download>
                        <Button onClick={() => {



                            that.setState({
                                showBeforeImportQuestionDialog: false,
                                importFileTypeError: false,
                                importFileFormatError: false,
                                importFileFormatChoicesError: false
                            });


                        }} bsStyle='primary'>Download Template</Button>
                    </a>

                    :

                    <a href="https://s3.amazonaws.com/learnerpal-data/Question/Template.xlsx" download>
                        <Button onClick={() => {



                            that.setState({
                                showBeforeImportQuestionDialog: false,
                                importFileTypeError: false,
                                importFileFormatError: false,
                                importFileFormatChoicesError: false
                            });


                        }} bsStyle='primary'>Download Template</Button>
                    </a>
                }

            </Modal.Footer>
            {bottomNote}
        </Modal>;
    }

    getQuestionStateChangedDialog() {

        // let text = "";
        // let count =1;
        // for(var question of this.state.questionsLst){
        //     text+= "<br>" + count++ + ": " + question.question.substring(0, 70).toString() + "...";
        // }

        let htmlToReactParser = new Parser();
        let body = <div id="importDialogContent">


            <h4>You have changed the state of {this.state.numberOfQuestionsStateUpdated} questions to '{this.state.questionsStateChanged}' </h4>

            <p>
                {/* {htmlToReactParser.parse(text)} */}
            </p>
            {/* {this.state.deleteError ? <Alert bsStyle="danger">
             {this.state.deleteErrorMessage}
             </Alert> : ''} */}
        </div>;
        let that = this;
        return <Modal
            backdrop="static"
            show={this.state.showQuestionStateChangedDialog}
            onHide={() => {
                this.setState({
                    showQuestionStateChangedDialog: false,
                    deleteError: false
                });

            }}
            container={this}
            aria-labelledby="contained-modal-title">
            <Modal.Header closeButton>
                <Modal.Title id="delete-activity-modal">Question State Changed</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {body}
            </Modal.Body>
            <Modal.Footer>

                {/* <Button onClick={() => {

                     that.setState({
                        showQuestionStateChangedDialog: false,
                     });

                }}>Close</Button>

                &nbsp; */}

                <Button onClick={() => {



                    that.setState({
                        showQuestionStateChangedDialog: false,
                    });


                }} bsStyle='primary'>Ok</Button>

            </Modal.Footer>
        </Modal>;
    }


    getDeleteDialog() {
        let body = <div id="deleteDialogContent">
            <p>
                Please confirm if you want to delete the question?
            </p>
            {this.state.deleteError ? <Alert bsStyle="danger">
                {this.state.deleteErrorMessage}
            </Alert> : ''}
        </div>;
        let that = this;
        return <Modal
            backdrop="static"
            show={this.state.showQuestionDeleteDialog}
            onHide={() => {
                this.setState({ showQuestionDeleteDialog: false, selectedRecord: null, deleteError: false });
            }}
            container={this}
            aria-labelledby="contained-modal-title">

            <Modal.Header closeButton>
                <Modal.Title id="delete-activity-modal">Delete Question</Modal.Title>

            </Modal.Header>
            <Modal.Body>
                {body}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => {
                    that.setState({ showQuestionDeleteDialog: false, selectedRecord: null, deleteError: false });
                }}>Cancel</Button>
                <Button onClick={() => {
                    that.deleteQuestion(that.state.selectedRecord);
                }} bsStyle='primary'>Delete</Button>
            </Modal.Footer>
        </Modal>;
    }


    deleteQuestion(selectedRecord) {

        let pathTemplate = '/question/' + selectedRecord.id + '/ownerName/' + this.props.auth.user.userName;
        let method = 'DELETE';

        let context = this;
        context.hideViewQuestionPage();
        this.setState({loading: true, showQuestionDeleteDialog: false, selectedRecord: null, deleteError: false})

        apiClient.invokeApi(null, pathTemplate, method, {}, {}, "4").then(function (result) {
            context.setState({
                showAlert: true,
                alertStyle: 'success',
                alertText: result.data
            });
            setTimeout(() => {
                context.setState({ showAlert: false });
            }, 7000);

            setTimeout(() => {
                if (context.child) { // Ensure the ref is not null
                    context.child.filterRecords(context.child.appliedFilters);
                } else {
                    context.requestAllQuestions();
                }
                setTimeout(() => {
                    this.setState({loading: false})
                }, 1000);
            }, 3000);

        }.bind(this)).catch(function (result) {

        });
    }

    exportQuestionsInExcel() {
        var arrayQuestions = [];

        const { dispatch } = this.props;
        let that = this;
        let elasticSearchQuery = new ElasticSearchQuery();
        let esAttr = this.props.elasticSearchAttributes.map.get('questions');
        that.showIndicator();


        elasticSearchQuery.query(ElasticSearchTypes.Question + config.stage, ElasticSearchColumns.Question, this.props,
            esAttr.searchTerm, this.props.manageQuestions.totalRecords, 1, { createdAt: { order: "desc" } }, esAttr.filterValues, function (results, totalRecords) {

                let data = [];
                let lstProperties = ["id", "question", "answer", "subjects", "choices", "category", "subcategory", "questionType", "explanation", "hint", "notes"];

                let header = ["id", "question", "answer", "subjects", "choiceA", "choiceB", "choiceC", "choiceD", "choiceE", "choiceF", "choiceG", "choiceH", "category", "subcategory", "questionType", "explanation", "hint", "notes"];
                data.push(header);

                let hasHeaderRow = false;
                for (var question of results) {

                    let row = [];

                    for (var propertyName of lstProperties) {

                        if (propertyName === "choices") {

                            for (let choiceCount = 0; choiceCount <= 7; choiceCount++) {
                                let choice = "";
                                if (question[propertyName] && choiceCount < question[propertyName].length)
                                    choice = question[propertyName][choiceCount].toString();
                                row.push(choice);
                            }

                            continue;
                        }

                        if (question.hasOwnProperty(propertyName)) {

                            row.push(question[propertyName].toString());

                        } else {
                            row.push("id");
                        }
                    }
                    data.push(row);
                }

                /* convert from array of arrays to workbook */
                var worksheet = XLSX.utils.aoa_to_sheet(data, { cellDates: true });
                var new_workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(new_workbook, worksheet, "Questions");

                /* write a workbook */
                function s2ab(s/*:string*/)/*:ArrayBuffer*/ {
                    const buf = new ArrayBuffer(s.length);
                    const view = new Uint8Array(buf);
                    for (let i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
                    return buf;
                }

                const wbout = XLSX.write(new_workbook, { type: 'binary', bookType: "xlsx" });
                XLSX.writeFile(new_workbook, Date.now() + "-questions.xlsx");

                // window.saveAs(new Blob([s2ab(wbout)], { type: "data:application/vnd.ms-excel" }), "questions.xlsx");
                // var link=window.URL.createObjectURL(new Blob([s2ab(wbout)], { type: "data:application/vnd.ms-excel" }));
                // window.location=link;

                that.hideIndicator();
            });
    }





    importExcelFile() {


        var inputElement = document.createElement("input");
        inputElement.type = "file";
        inputElement.accept = "*"; // Note Edge does not support this attribute
        inputElement.multiple = false;
        inputElement.dispatchEvent(new MouseEvent("click"));

        let that = this;
        inputElement.addEventListener('change', function (evt) {


            var fileTobeRead = inputElement.files[0];

            if (fileTobeRead.name.search(/xls|xlsx/ig) == -1) {
                that.hideIndicator();

                that.setState({
                    importFileTypeError: true,
                    importFileFormatError: false,
                });

                // that.setState({
                //     // showBeforeImportQuestionDialog: false,
                //     importFileTypeError: true,
                //     showAlert: true,
                //     alertStyle: 'danger',
                //     alertText: "Invalid file. Please upload the template again."
                // });

                // setTimeout(() => {
                //     that.setState({ showAlert: false });
                // }, 5000);

                return;
            }

            var reader = new FileReader();
            that.showIndicator();
            reader.onload = function (e) {
                let xldata = e.target.result;
                let wb = XLSX.read(xldata, { type: 'binary' });

                var result = {};
                wb.SheetNames.forEach(function (sheetName) {
                    var roa = XLSX.utils.sheet_to_json(wb.Sheets[sheetName]);
                    if (roa.length) result[sheetName] = roa;
                });

                let workSheetName = wb.SheetNames[0];
                let rows = result[workSheetName];

                if (!rows) {
                    that.hideIndicator();
                    that.setState({
                        importFileTypeError: false,
                        importFileFormatError: true,
                    });
                    return;
                }

                let importSubjects = [];

                var createQuestionsLst = [], updateQuestionsLst = [];
                for (let row of rows) {

                    // if by mistake user inserted empty spaces in the column (not a single letter other than spaces)
                    let valueInRow = Object.values(row);
                    let emptyProblem = 0
                    valueInRow.forEach(x => {
                        let value = x.trim()
                        if (!value) {
                            emptyProblem++;
                        }
                    })
                    if (emptyProblem == valueInRow.length) {
                        continue;
                    }


                    let noOfchoices = 0;


                    // let questionData = { questionState: "In Progress" };
                    let questionData = that.props.auth.user.schoolName === "LearnerPal" ? { questionState: "Shared" } : { questionState: "In Progress" };
                    let glossarySection = "";

                    for (let propName in row) {

                        let value = row[propName].trim();
                        if ((propName === "subjects" || propName.toLowerCase() === "subject") && value) {

                            questionData["subjects"] = value.toString().split(",");
                        } else if (propName.toLowerCase() === "image" && value) {

                            questionData.question = questionData.question + `

                            <table style="width: 100%;">
                                <tbody>
                                    <tr>
                                        <td style="width: 50.0000%;">[[[LP_ANSWERS]]]</td>
                                        <td style="width: 50.0000%;"><img src="`+ value + `"/><br></td>
                                    </tr>
                                </tbody>
                            </table>`;

                        } else if (propName.toLowerCase().indexOf("definition") > -1 && value) {
                            value = value.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;')
                            let glossarySeperatorIndex = value.indexOf(':')
                            if (glossarySeperatorIndex >= 0) {

                                let word = value.slice(0, glossarySeperatorIndex);
                                let definition = value.slice(glossarySeperatorIndex);
                                glossarySection += " <span style=\"font-size: 16px; font-family: Verdana, Geneva, sans-serif;\"><strong>" + word + "</strong>" + definition + "<br/><br/></span>";
                            } else {

                                glossarySection += value + "<br/><br/>";
                            }

                            // questionData[propName] = value;

                        } else if (propName.toLowerCase().indexOf("choice") > -1 && value) {

                            if (row[propName] === undefined || row[propName].length < 1)
                                continue;

                            if (questionData["choices"] === undefined)
                                questionData["choices"] = [];
                            
                            value = value.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;')
                            noOfchoices++;
                            questionData["choices"].push(value);
                            //questionData["choices"].push({ option: value, status: isAnswer.toString() });
                        } else if (propName.toLowerCase().indexOf("notes") > -1 && value) {
                            value = value.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;')
                            questionData["notes"] = value;
                        } else if (propName.toLowerCase().indexOf("answer") > -1 && value) {
                            value = value.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;')
                            questionData["answer"] = value;
                        } else if ((propName.toLowerCase().indexOf("questionid") > -1 || propName.toLowerCase().indexOf("id") > -1) && value) {
                            value = value.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;')
                            questionData["questionID"] = value;
                        } else {
                            value = value.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;')
                            if (value)
                                questionData[propName.toLowerCase()] = value;
                        }
                    }


                    // this will produce an error if file does not have the subject OR category OR subCategory
                    // handling above error



                    if (!questionData.question || !questionData.subjects || !questionData.category || !questionData.subcategory || !questionData.answer || noOfchoices < 2) {
                        that.hideIndicator();
                        that.setState({
                            importFileTypeError: false,
                            importFileFormatError: true,
                        });
                        return;
                    }



                    if (!questionData.choices[questionData.answer - 1]) {
                        that.hideIndicator();
                        that.setState({
                            importFileTypeError: false,
                            importFileFormatChoicesError: true,
                            // importFileFormatError: true,
                        });
                        return;
                    }



                    // update answer after the choices have been filed
                    if (questionData.answer) {
                        questionData.answer = questionData.choices[questionData.answer - 1];
                    }


                    let customSubject = that.state.customSubjects.find(x => x.name.toLowerCase() === questionData.subjects[0].toLowerCase());
                    if (!customSubject)
                        customSubject = { name: questionData.subjects[0] };

                    if (!customSubject.categories)
                        customSubject.categories = [];

                    let category = customSubject.categories.find(x => x.category.toLowerCase() === questionData.category.toLowerCase());
                    if (!category) {
                        category = { category: questionData.category, subCategories: [] };
                        customSubject.categories.push(category);
                    }

                    let subCategory = category.subCategories.find(x => x.subCategory.toLowerCase() === questionData.subcategory.toLowerCase());
                    if (!subCategory) {

                        subCategory = { subCategory: questionData.subcategory };
                        category.subCategories.push(subCategory);
                    }


                    importSubjects.push(customSubject);


                    questionData.schoolName = that.props.auth.user.schoolName;
                    questionData.ownerName = that.props.auth.user.userName;

                    if (questionData.questionType === undefined) {
                        questionData.questionType = "Multiple Choice";
                        if (questionData.choices == undefined)
                            questionData.questionType = "Essay";
                    }

                    //todo: set the question state to in progress ..

                    // if (this.props.auth.user.schoolName === "LearnerPal" || this.props.auth.user.userType.toLowerCase() === "lpadmin")
                    //     questionData.questionState = "In Progress";

                    if (glossarySection) {
                        questionData.explanation += "<div><hr/>" + glossarySection + "</div>";
                    }

                    questionData.question = "<span style=\"font-family: Verdana, Geneva, sans-serif; \"><strong><span style=\"font-size: 17px;\">" + questionData.question + "</span></strong></span>"
                    questionData.explanation = "<span style=\"font-family: Verdana, Geneva, sans-serif; \"><span style=\"font-size: 17px;\">" + questionData.explanation + "</span></span>"

                    if (row["id"] || row["ID"] || row["Id"] || row["questionID"] || row["questionId"] || row["questionid"]) {
                        updateQuestionsLst.push(questionData);
                    } else {
                        createQuestionsLst.push(questionData);
                        createQuestionsLst = createQuestionsLst.reverse();
                    }
                }




                // setting importSubjects for DB and UI

                let importSubjectsObj = []
                let uniqueSubjects = [... new Set(importSubjects.map(subObj => subObj.name))]
                if (uniqueSubjects.length > 0) {
                    for (let singleUniqueSubject of uniqueSubjects) {
                        let payLoadObj = {}
                        let singleSubject = importSubjects.filter(sub => sub.name == singleUniqueSubject)
                        payLoadObj.name = singleUniqueSubject;
                        payLoadObj.categories = [];
                        if (singleSubject) {
                            for (let innerSubject of singleSubject) {
                                for (let innerCategories of innerSubject.categories) {
                                    let innerCategory = payLoadObj.categories.find(x => x.category == innerCategories.category)
                                    if (!innerCategory) {
                                        payLoadObj.categories.push(innerCategories)
                                    }
                                    else {
                                        for (let innerSubCate of innerCategories.subCategories) {
                                            let innerSubCategory = innerCategory.subCategories.find(x => x.subCategory == innerSubCate.subCategory)
                                            if (!innerSubCategory) {
                                                innerCategory.subCategories.push(innerSubCate)
                                            } else {
                                                // all good
                                            }
                                        }
                                    }
                                }
                            }
                            importSubjectsObj.push(payLoadObj)
                        }
                    }
                }




                that.hideIndicator();


                //that.setState({ questionsLst: questionsLst, showImportQuestionDialog: true });
                that.setState({
                    createQuestionsLst: createQuestionsLst,
                    updateQuestionsLst: updateQuestionsLst,
                    importSubjects: importSubjectsObj,
                    showImportQuestionDialog: true,
                    importFileTypeError: false,
                    importFileFormatError: false,
                    importFileFormatChoicesError: false
                });


            }

            reader.readAsBinaryString(fileTobeRead);

        }, false);
    }

    dialogueBoxCreateQuestion() {

        return <Modal id="dialog1"
            show={this.state.showClassAssignmentMessage2}
            onHide={() => {
                this.setState({ showClassAssignmentMessage2: false });
            }}

            container={this}
            backdrop="false"
            aria-labelledby="contained-modal-title">
            <Modal.Header closeButton>
                <h1>Success</h1>
            </Modal.Header>
            <Modal.Body>

                <h3>{this.state.questionContent}</h3>

            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => {
                    this.setState({ showClassAssignmentMessage2: false })

                }} bsStyle='primary'>OK</Button>

            </Modal.Footer>
        </Modal>;

    }


    UpdateQuestiondialogueBox() {

        return <Modal id="dialog1"
            show={this.state.showClassAssignmentMessagesubmit}
            onHide={() => {

                this.setState({ showClassAssignmentMessagesubmit: false });
            }}

            container={this}
            aria-labelledby="contained-modal-title">
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
                <h3>Your Question has been modified</h3>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => {
                    this.setState({ showClassAssignmentMessagesubmit: false })

                }} bsStyle='primary'>OK</Button>

            </Modal.Footer>
        </Modal>;

    }



    dialogueBoxClosingEditor() {

        return <Modal id="dialogCustomEntry"
            container={this}
            show={this.state.showClassAssignmentMessage1}
            backdrop="static"
            onHide={() => {

                this.setState({ showClassAssignmentMessage1: false });
            }}

            aria-labelledby="contained-modal-title">
            <Modal.Header closeButton>
                <Modal.Title id="delete-activity-modal">Confirm</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <h3>Your changes will not be saved. Are you sure you want to close the editor?</h3>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => {
                    this.setState({ showClassAssignmentMessage1: false, showDuplicateQuestionDialogBox: false, showCreateQuestionDialogBox: false, showModifyQuestionDialogBox: false });

                    if (this.state.backToLandingPage) {
                        this.setState({ showClassAssignmentMessage1: false, isActivityTypeLandingPage: true, isLandingPage: true, backToLandingPage: false });
                    }
                }} bsStyle='primary'>Yes</Button>

                <Button onClick={() => {

                    this.setState({ showClassAssignmentMessage1: false });
                }} bsStyle='primary'>No</Button>

            </Modal.Footer>
        </Modal>;

    }

    propertyChangedHandler1(event) {

        let e = event;
        let that = this;
        let update = { [e.target.name]: e.target.value };
        let propName = e.target.name;
        let propValue = e.target.value;

        let id = e.target.name[e.target.name.length - 1];

        if (e.target.name.startsWith("selectedSubject")) {


            this.state['selectedCategory' + id] = "";
            this.state['selectedSubCategory' + id] = "";

            this.requestElasticSearch(e.target.value, this.state['selectedCategory' + id]);
        } else if (e.target.name.startsWith("selectedCategory")) {
            this.state['selectedSubCategory' + id] = "";
            this.requestElasticSearch(this.state['selectedSubject' + e.target.name[e.target.name.length - 1]], e.target.value);
        }

        if (e.target.value == "Add a Custom Subject") {

            that.setState({ showClassAssignmentMessage101: true });
        }

        this.setState(update, function () {

            if (propName !== "selectedSubCategory")
                return;

            let filters = [];

            if (that.state.selectedSubject)

                filters.push({ terms: { subjects: [that.state.selectedSubject] } });



            if (that.state.selectedCategory)

                filters.push({ terms: { category: [that.state.selectedCategory] } });

            if (propValue)

                filters.push({ terms: { subcategory: [propValue] } });
        });
    }

    propertyChangedHandler(event) {


        let e = event;
        let propName = e.target.name;
        let propValue = e.target.value;

        this.handlePropertyChanged(propName, propValue);

    }


    handlePropertyChanged(propName, propValue) {
        let isLPAdmin = this.props.auth.user.schoolName === "LearnerPal" && this.props.auth.user.userType.toLowerCase() === "admin";

        let that = this;
        let update = { [propName]: propValue };

        if (propName == "selectedQuestionType") {
            this.setState({ showCreateQuestionDialogBox: true })
        }

        if (propName == "selectedSubject") {

            update.selectedCategory = "";
            update.selectedSubCategory = "";
            update.selectedQuestionType = "";

            this.state.selectedCategory = "";
            this.state.selectedSubCategory = "";

            this.state.allCategories = [];
            this.state.allSubCategories = [];

            // let subject = this.state.customSubjects.find(x => x.id.includes(propValue));
            let subject = this.state.customSubjects.find(x => x.id == propValue);
            if (subject && subject.categories) {
                let lstCategories = [];
                for (let categ of subject.categories) {
                    lstCategories.push({ id: categ.category, name: categ.category });
                }

                this.sortList(lstCategories);

                this.state.allCategories = lstCategories;
            }

        } else if (propName == "selectedCategory") {

            update.selectedSubCategory = "";
            update.selectedQuestionType = "";

            this.state.selectedSubCategory = "";
            this.state.allSubCategories = [];

            let subject = this.state.customSubjects.find(x => x.id === this.state.selectedSubject);
            if (subject && subject.categories) {
                let category = subject.categories.find(y => y.category === propValue)
                if (category && category.subCategories) {
                    let lstSubCategories = [];
                    for (let categ of category.subCategories) {
                        if (categ.subCategory) {

                            lstSubCategories.push({ id: categ.subCategory, name: categ.subCategory });
                        }
                        else if (categ.subcategory) {
                            console.log(categ);

                            lstSubCategories.push({ id: categ.subcategory, name: categ.subcategory });
                        }
                        else {
                            console.log("IT`S NOT NORMAL")
                        }
                    }

                    this.sortList(lstSubCategories, true);

                    this.state.allSubCategories = lstSubCategories;
                }
            }
        }

        this.setState(update, function () {

            if ((propName === "selectedSubCategory" || propName == "selectedSubject" || propName == "selectedCategory" )){
                if (!this.state.addCustomSubjects)
                this.requestAllQuestions();
                return;
            }
            if (propName !== "selectedSubCategory")
                return;

            if (!this.state.addCustomSubjects)
                this.requestAllQuestions();

        });
    }




    requestAllQuestions(dataForCallback, callback) {
        this.showIndicator();
        let filters = [];
        let that = this;


        if (that.state.selectedSubject)
            filters.push({ terms: { subjects: [that.state.selectedSubject] } });

        if (that.state.selectedCategory)
            filters.push({ terms: { category: [that.state.selectedCategory] } });

        if (that.state.selectedSubCategory)
            filters.push({ terms: { subcategory: [that.state.selectedSubCategory] } });


        let esAttr = that.props.elasticSearchAttributes.map.get('questions');
        const { dispatch } = that.props;
        ElasticSearchActions.setElasticSearchAttributes(dispatch, 'questions', esAttr.searchTerm, esAttr.pageSize, esAttr.activePage, esAttr.sortObj, filters);
        let elasticSearchQuery = new ElasticSearchQuery();
        elasticSearchQuery.query(ElasticSearchTypes.Question + config.stage, ElasticSearchColumns.Question, that.props,
            esAttr.searchTerm, esAttr.pageSize, esAttr.activePage, { createdAt: { order: "desc" } }, filters, function (results, totalRecords) {
                dispatch({
                    type: RECEIVED_QUESTIONS_RESULTS,
                    status: 200,
                    data: JSON.parse(JSON.stringify(results)),
                    alertText: '',
                    alertStyle: '',
                    showAlert: false,
                    totalRecords: totalRecords
                });
                callback && callback(that, dataForCallback, results);
                !callback && that.hideIndicator();
            });
    }


    questionStatePropertyChangedHandler(event) {

        this.showIndicator();

        let e = event;
        let that = this;
        let update = { [e.target.name]: e.target.value };
        let propName = e.target.name;
        let propValue = e.target.value;

        if (propValue == 'Pending')
            this.setState({ questionsStateChanged: 'In Progress' });
        else if (propValue == 'Publish')
            this.setState({ questionsStateChanged: 'Published' });
        else
            this.setState({ questionsStateChanged: propValue });

        let questionState = propValue;

        let body = {
            ownerName: this.props.auth.user.userName,
            schoolName: this.props.auth.user.schoolName
        }

        // let axiosInstance = axios.create({
        //     baseURL: 'https://search-learnerpal-eimkio3umgx5fnpuy7ypsrrsri.us-east-1.es.amazonaws.com/learnerpal/',
        //     timeout: 60000,
        //     headers: { 'Content-Type': 'application/json' },
        //     responseType: 'json',
        //     transformRequest: [function (data) {
        //         return data;
        //     }],
        //     transformResponse: {
        //         function(data) {
        //
        //             let questionIds = [];
        //             if (data && data.hits && data.hits.hits && data.hits.hits.length > 0) {
        //                 data.hits.hits.forEach(questionElement => {
        //                     questionIds.push(questionElement._source.id);
        //                 })
        //             }
        //
        //             return questionIds;
        //         }
        //     }
        // });
        const isLPAdmin = this.props.auth.user.userType.toLowerCase() === "admin" && this.props.auth.user.schoolName === "LearnerPal";
        let visibility = '("' + this.props.auth.user.userName + '"';
        if (isLPAdmin) {
            visibility += " OR public OR LPAdmin";
        }

        visibility += ')';

        let subjectsQuery = 'subjects: ("' + this.state.selectedSubject + '") ';
        if(this.state.selectedCategory) {
            subjectsQuery += 'AND category: ("' + this.state.selectedCategory + '") ';
        } 
        if(this.state.selectedSubCategory){
            subjectsQuery += 'AND subcategory: ("' + this.state.selectedSubCategory + '") ';
        }
        let subjectsQueryString = subjectsQuery + " AND visibleTo : " + visibility
        if(isLPAdmin){
            subjectsQueryString = subjectsQuery
        }

        let queryObject = {

            "_source": ["subjects", "id"],
            "query": {
                "bool": {
                    "must": [
                        {
                            "query_string": {
                                "query": subjectsQueryString
                            }
                        }
                    ]
                }
            },
            "size": 1000
        };

        let type = ElasticSearchTypes.Question + config.stage;
        let context = this;

        apiClient.invokeApi({}, '/getesdata', 'POST', {}, { payload: queryObject, index: 'learnerpal/', esDocumentType: type }, '5').then(function (response) {

            // axiosInstance({
            //     method: 'post',
            //     url: '/' + type + '/_search',
            //     data: JSON.stringify(queryObject)
            // }).then(response => {

            let data = response.data;

            let questionIds = [];
            if (data && data.hits && data.hits.hits && data.hits.hits.length > 0) {
                data.hits.hits.forEach(questionElement => {
                    questionIds.push(questionElement._source.id);
                })
            }

            response.data = questionIds;

            let listOfQuestions = response.data;

            body.questionIds = response.data;
            body.questionState = questionState;

            apiClient.invokeApi({}, '/updateQuestionStateByQuestionIDs', 'put', {}, body, "5")
                .then(function (result) {

                    // After successfully update all the question state
                    context.setState({ numberOfQuestionsStateUpdated: listOfQuestions.length });
                    context.hideIndicator();
                    context.setState({ showQuestionStateChangedDialog: true })


                    setTimeout(() => {

                        context.requestAllQuestions();
                    }, 1000);

                    // console.log(result);
                }).catch(function (result) {

                    console.log("error occured while processing data from /updateQuestionStateByQuestionIDs ");
                    console.log(result);
                    context.hideIndicator();
                });

        }).catch();





        // setTimeout(()=> {
        //     this.hideIndicator();
        //     this.setState({ showQuestionStateChangedDialog: true })
        // }, 2000)


        //    return;

    }

    getAllUserSubjects() {

        //requesting from elastic search ..

        let sortedSubjects = []
        this.props.auth.user.subjects.forEach(sub => {

            sortedSubjects.push({ "id": sub, "name": sub });
        })

        this.updateSubjectsList(sortedSubjects);
    }


    async getAllSubjects() {
        let context = this;
        context.showIndicator();

        let schoolName = this.props.auth.user.schoolName ? this.props.auth.user.schoolName : 'trial';
        let params = {
            schoolName: schoolName,
            ownerName: this.props.auth.user.userName,
        };
        let pathTemplate = '/subjects/ownerName/{ownerName}/schoolName/{schoolName}';
        let method = 'GET';
        let additionalParams = {};
        let body = {};
        apiClient.invokeApi(params, pathTemplate, method, additionalParams, body).then((result) => {

            context.setState({
                ...context.state,
                // allSubjects: result.data,
                customSubjects: result.data
            }, () => {
                this.updateSubjectsList([]);
            });

            context.hideIndicator();
        }).catch(function (error) {
            context.hideIndicator();
        });

        this.getAllUserSubjects();
    }



    updateSubjectsList(sortedSubjects) {

        let { subjects } = this.props.auth.user;
        // if (subjects) {

        //     subjects.forEach(subject => {

        //         if (!sortedSubjects.find(x => x.name == subject)) {

        //             sortedSubjects.push({ "id": subject, "name": subject });
        //         }
        //     });
        // }

        if (this.state.customSubjects) {
            var lstSubjects = [];

            this.state.customSubjects.forEach(x => {

                let subjectName = x.name;

                if (!subjects.find(y => y == subjectName))
                    return;

                lstSubjects.push({ "id": subjectName, "name": subjectName });
            });

            sortedSubjects = lstSubjects;
        }

        //working
        this.sortList(sortedSubjects);
        this.setState({ allSubjects: sortedSubjects });
    }

    sortList(sortSubject, shouldChronological) {

        sortSubject.sort(function (a, b) {
            return a.id.localeCompare(b.id, undefined, {
                numeric: true,
                sensitivity: 'base'
            });
        });

        if (shouldChronological) {

            var allMonths = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'];
            var regexYear = /(19[0-9]{2}|20[0-9]{2})/;
            let chronoObj = [];
            let sortSubjectCopy = [];

            sortSubject.forEach(x => {

                let sourceId = x.id;

                let firstSpaceIndex = sourceId.indexOf(' ');
                let extractedMonth = sourceId.slice(0, firstSpaceIndex)

                if (!(extractedMonth) ? allMonths.includes(extractedMonth.toLowerCase()) : null)
                    return;

                let yearMatched = regexYear.exec(sourceId);
                if (!yearMatched)
                    return;

                let innerObj = chronoObj.find(x => x.year === yearMatched[0])
                if (!innerObj) {

                    let obj = {};

                    obj['year'] = yearMatched[0];

                    obj['months'] = [];
                    obj.months.push({
                        month: extractedMonth,
                        data: [x]
                    });
                    chronoObj.push(obj);
                } else {
                    let innerMonthsObj = innerObj.months.find(x => x.month === extractedMonth);
                    if (!innerMonthsObj) {
                        innerObj.months.push({
                            month: extractedMonth,
                            data: [x]
                        });

                    } else {
                        innerMonthsObj.data.push(x);
                    }
                }
            })


            chronoObj.sort((a, b) => {
                return a.year - b.year;
            });

            chronoObj.forEach(x => {
                x.months.sort((a, b) => {
                    return allMonths.indexOf(a.month.toLowerCase()) > allMonths.indexOf(b.month.toLowerCase());
                });
            });

            chronoObj.forEach(x => x.months.forEach(x => {
                x.data.forEach(x => {
                    sortSubjectCopy.push(x);
                })
            })
            );

            if (sortSubjectCopy.length > 0) {
                sortSubject.sort((a, b) => {

                    var valA = a;
                    var valB = b;

                    if (sortSubjectCopy.indexOf(valA) < sortSubjectCopy.indexOf(valB)) {
                        return -1;
                    }

                    if (sortSubjectCopy.indexOf(valA) > sortSubjectCopy.indexOf(valB)) {
                        return 1;
                    }

                    return 0;

                });
            }
        }
    }

    //learnerpal - get all the subjects
    requestElasticSearchForAllSubjects() {

        // let axiosInstance = axios.create({
        //     baseURL: 'https://search-learnerpal-eimkio3umgx5fnpuy7ypsrrsri.us-east-1.es.amazonaws.com/learnerpal/',
        //     timeout: 60000,
        //     headers: { 'Content-Type': 'application/json' },
        //     responseType: 'json',
        //     transformRequest: [function (data) {
        //         return data;
        //     }],
        //     transformResponse: {
        //         function(data) {
        //
        //             let reponse = [];
        //             if (data.aggregations.subject) {
        //
        //                 data.aggregations.subject.buckets.forEach(value => {
        //                     reponse.push({ name: value.key, count: value.doc_count })
        //                 });
        //             }
        //
        //             return reponse;
        //         }
        //     }
        // });

        let type = ElasticSearchTypes.Question + config.stage;


        let visibility = '(public OR "' + this.props.auth.user.userName + '"';
        if (this.props.auth.user.userType.toLowerCase() === "admin" && this.props.auth.user.schoolName === "LearnerPal") {
            visibility += " OR LPAdmin";
        }

        visibility += ')';

        let subjectsQueryString = "visibleTo : " + visibility
        const isLPAdmin = this.props.auth.user.userType.toLowerCase() === "admin" && this.props.auth.user.schoolName === "LearnerPal";

        if (this.props.auth.user.schoolName === "LearnerPal")
            subjectsQueryString = subjectsQueryString + " OR questionState2: (\"In Review\") OR questionState2: (\"Shared\")"
        if(isLPAdmin){
            subjectsQueryString = '';
        }
        let queryObject = {

            query: {
                bool: {
                    must: { query_string: { query: subjectsQueryString } },
                }
            },
            aggs: { subject: { terms: { field: "subjects", size: 100 } } },
        };

        let context = this;
        apiClient.invokeApi({}, '/getesdata', 'POST', {}, { payload: queryObject, index: 'learnerpal/', esDocumentType: type }, '5').then(function (response) {

            // axiosInstance({
            //     method: 'post',
            //     url: '/' + type + '/_search',
            //     data: JSON.stringify(queryObject)
            // }).then(response => {

            let data = response.data;

            let reponse = [];
            if (data.aggregations.subject) {

                data.aggregations.subject.buckets.forEach(value => {
                    reponse.push({ name: value.key, count: value.doc_count })
                });
            }

            response.data = reponse

            var subjects = [];
            response.data.forEach(subject => {
                subjects.push({ id: subject.name, name: subject.name });
            })

            context.updateSubjectsList(subjects);
            // context.setState({ allSubjects: subjects });

        }).catch();
    }



    requestElasticSearch(subjects, category, callback) {

        subjects = [subjects];

        // let axiosInstance = axios.create({
        //     baseURL: 'https://search-learnerpal-eimkio3umgx5fnpuy7ypsrrsri.us-east-1.es.amazonaws.com/learnerpal/',
        //     timeout: 60000,
        //     headers: { 'Content-Type': 'application/json' },
        //     responseType: 'json',
        //     transformRequest: [function (data) {
        //         return data;
        //     }],
        //     transformResponse: {
        //         function(data) {
        //             let reponse = [];
        //             if (data.aggregations) {
        //                 for (let key in data.aggregations) {
        //                     if (data.aggregations.hasOwnProperty(key)) {
        //                         let values = [];
        //                         data.aggregations[key].buckets.forEach(value => {
        //                             values.push({ name: value.key, count: value.doc_count })
        //                         });
        //
        //                         reponse.push({ label: key, allValues: values });
        //                     }
        //                 }
        //             }
        //             return reponse;
        //         }
        //     }
        // });


        let visibility = '(public OR "' + this.props.auth.user.userName + '"';
        if (this.props.auth.user.userType.toLowerCase() === "admin" && this.props.auth.user.schoolName === "LearnerPal") {
            visibility += " OR LPAdmin";
        }
        visibility += ')';

        let subjectsToSearch = "";
        if (subjects) {
            subjects.forEach(sub => {
                subjectsToSearch += "\"" + sub + "\" OR ";
            })
        }

        subjectsToSearch = subjectsToSearch.substring(0, subjectsToSearch.length - 3);



        let visibilityQuery = "visibleTo : " + visibility
        const isLPAdmin = this.props.auth.user.userType.toLowerCase() === "admin" && this.props.auth.user.schoolName === "LearnerPal";

        if (this.props.auth.user.schoolName === "LearnerPal")
            visibilityQuery = "( " + visibilityQuery + " OR questionState2: (\"In Review\") OR questionState2: (\"Shared\"))"

        let subjectsQueryString = visibilityQuery + " AND subjects : (" + subjectsToSearch + ")";

        let type = ElasticSearchTypes.Question + config.stage;

        let aggs = {
            category: { terms: { field: "category", size: 100 } },
        };

        if (category) {
            aggs.subcategory = { terms: { field: "subcategory", size: 100 } }

            subjectsQueryString += " AND category:(\"" + category + "\")";
        }
        if(isLPAdmin){
            subjectsQueryString = '';
            subjects=[];
            aggs={};
        }

        let queryObject = {

            query: {
                bool: {
                    must: { query_string: { query: subjectsQueryString } },

                    filter: { bool: { must: [{ terms: { subjects: subjects } }] } }
                }
            },
            aggs: aggs,
        };

        apiClient.invokeApi({}, '/getesdata', 'POST', {}, { payload: queryObject, index: 'learnerpal/', esDocumentType: type }, '5').then(response => {
            // axiosInstance({
            //     method: 'post',
            //     url: '/' + type + '/_search',
            //     data: JSON.stringify(queryObject)
            // }).then(response => {
            let categories = [];
            let allSubCategories = [];

            if (response && response.data) {


                let dataforAgg = response.data;
                let reponseAgg = [];
                if (dataforAgg.aggregations) {
                    for (let key in dataforAgg.aggregations) {
                        if (dataforAgg.aggregations.hasOwnProperty(key)) {
                            let values = [];
                            dataforAgg.aggregations[key].buckets.forEach(value => {
                                values.push({ name: value.key, count: value.doc_count })
                            });
                            reponseAgg.push({ label: key, allValues: values });
                            //console.log(key + " -> " + dataforAgg.aggregations[key]);
                        }
                    }
                }


                for (let data of reponseAgg) {

                    if (data.label === "category") {
                        for (let categ of data.allValues) {
                            categories.push({ id: categ.name, name: categ.name });
                        }

                    }
                    else if (data.label === "subcategory") {

                        for (let categ of data.allValues) {
                            allSubCategories.push({ id: categ.name, name: categ.name });
                        }

                    }
                }
            }

            // get custom subjects
            if (this.state.customSubjects) {

                let subject = this.state.customSubjects.find(x => x.name === subjects[0]);

                if (subject) {

                    let selectedCategory = null;
                    if (subject.categories) {

                        subject.categories.forEach(categ => {

                            if (categ.category === this.state.selectedCategory)
                                selectedCategory = categ;

                            if (categories.find(x => x.name == categ.category)) {
                                return;
                            }

                            categories.push({ id: categ.category, name: categ.category, state: categ.state, subCategories: categ.subCategories })
                        })
                    }

                    if (selectedCategory && selectedCategory.subCategories) {
                        selectedCategory.subCategories.forEach((subCateg) => {

                            if (allSubCategories.find(x => x.id == subCateg.subCategory)) {
                                return;
                            }

                            allSubCategories.push({ id: subCateg.subCategory, name: subCateg.subCategory, state: subCateg.state })
                        });
                    }

                }
            }

            //this.setState({ allCategories: categories, allSubCategories: allSubCategories });

            this.sortList(categories);
            this.sortList(allSubCategories, true);

            if (category) {
                this.setState({ allSubCategories: allSubCategories });
            } else {
                this.setState({ allCategories: categories, allSubCategories: allSubCategories });
            }

        }).catch()

        if (callback)
            callback("hello world")
    }


    getQuestionFilters() {

        let allQuestionTypes = [
            {
                "id": "Multiple Choice",
                "name": "Multiple Choice"
            },
            {
                "id": "essay",
                "name": "Essay"
            },
        ];

        let isLPAdmin = this.props.auth.user.schoolName === "LearnerPal" && this.props.auth.user.userType.toLowerCase() === "admin";



        let subjectsControl =
            <SelectFieldGroup
                // ref="subjectsControl"
                dropDownId="subjectDropDownId"
                // label="Subject:"
                value={this.state.selectedSubject}
                field="selectedSubject"
                options={this.state.allSubjects}
                placeholder="Select Subject"
                onChange={this.propertyChangedHandler}
            />

        let categoryControl =
            <SelectFieldGroup
                // ref="categoryControl"
                dropDownId="categoryDropDownId"
                // label="Category:"
                field="selectedCategory"
                value={this.state.selectedCategory}
                options={this.state.allCategories}
                placeholder="Select Category"
                onChange={this.propertyChangedHandler}
            />

        let subCategoryControl =
            <SelectFieldGroup
                // ref="subcategoryControl"
                dropDownId="subcategoryDropDownId"
                // label="Subcategory:"
                field="selectedSubCategory"
                value={this.state.selectedSubCategory}
                options={this.state.allSubCategories}
                placeholder="Select Subcategory"
                onChange={this.propertyChangedHandler}
            />;

        let allSelected = this.state.selectedSubject && this.state.selectedCategory && this.state.selectedSubCategory;

        let states = [
            {
                id: "In Progress",
                name: "In Progress"
            },
            // {
            //     id: "In Review",
            //     name: "In Review"
            // },
            {
                id: "Published",
                name: "Published"
            },
            {
                id: "Shared",
                name: "Shared"
            },
        ]

        return (
            <div>
                <div className="k" style={{ fontsize: '2px' }}>
                    <h1 style={{ paddingLeft: '17px' }}><strong>Select Subject, Category, and Subcategory to view questions, and edit/add questions within that listing</strong></h1>
                </div>
                <div className="mannageQuestionSelectFields" style={{ padding: '0px', marginLeft: '15px', border: '0px' }}>
                    <div className="row">
                        <div className="col-md-4">
                            {subjectsControl}
                        </div>
                        <div className="col-md-4">
                            {categoryControl}
                        </div>
                        <div className="col-md-4">
                            {subCategoryControl}
                        </div>
                    </div>
                    <div className={allSelected ? "mannageQuestionSelectFildsGrid2" : 'mannageQuestionSelectFildsGrid1' }>
                        {this.state.selectedSubCategory &&
                            <Button style={{ width: "155px", height: "40px" }}
                                onClick={() => {
                                    this.setState({ showQuestionChoices: !this.state.showQuestionChoices });
                                }}

                                bsStyle='primary'>Add New Question</Button>
                        }

                        {this.state.showQuestionChoices && <SelectFieldGroup
                            error={this.state.error.questionType}
                            dropDownId="subcategoryDropDownId"
                            // label="Add a new"
                            field="selectedQuestionType"
                            // value={this.state.selectedQuestionType}
                            options={this.state.allQuestionTypes}
                            placeholder="Question Type"
                            onChange={(e) => {

                                if (!allSelected) {
                                    alert('You must select Subject, Category and Subcategory before adding a new question');
                                    return;
                                }

                                this.propertyChangedHandler(e);
                            }}
                        />}

                        {/* {this.state.addMoreHtmlArray1} */}

                        {this.state.selectedSubCategory && isLPAdmin && <Button style={{ width: "75px", height: "40px" }} onClick={this.exportQuestionsInExcel} bsStyle='primary'>Export</Button>}

                        {this.state.selectedSubject && isLPAdmin &&
                            <Button style={{ width: "180px", height: "40px", position: 'relative' }}
                                onClick={() => {
                                    this.setState({ showQuestionStateChange: !this.state.showQuestionStateChange });
                                }}


                                bsStyle='primary'>Change Questions State</Button>
                        }

                        {this.state.showQuestionStateChange && <div style={{ position: 'relative' }}><SelectFieldGroup
                            error={this.state.error.questionType}
                            dropDownId="questionStateDropDownId"
                            // label="Add a new"
                            field="selectedQuestionState"
                            // value={this.state.selectedQuestionType}
                            options={states}
                            placeholder="Select State"
                            onChange={(e) => {

                                // if (!allSelected) {
                                //     alert('You must select Subject, Category and Subcategory before adding a new question');
                                //     return;
                                // }

                                this.questionStatePropertyChangedHandler(e);
                            }}
                        /></div>}



                    </div>

                </div>
            </div>

        );
    }


    choiceButton() {

        let allQuestionTypes = [
            {
                "id": "Multiple Choice",
                "name": "Multiple Choice"
            },
            {
                "id": "essay",
                "name": "Essay"

            },
        ];



        if (!this.state.selectedSubject) {
            let error = {};
            // error.questionType = "Question type is required.";
            // error.title = "Question Type";
            alert('You must select Subject, Category and Subcategory before adding a new question');
            this.setState({ error: error });

            return;
        }


        let Choice = <div style={{
            marginTop: '-4%',

            display: 'inline-block',
            width: '63%',
            marginLeft: '13%'
        }}><SelectFieldGroup
                error={this.state.error.questionType}
                dropDownId="subcategoryDropDownId"
                // label="Add a new"
                field="selectedQuestionType"
                value={this.state.selectedQuestionType}
                options={allQuestionTypes}
                placeholder="Question Type"
                onChange={this.propertyChangedHandler}
            />
        </div>
        // {Choice}


        let tempArray1 = this.state.addMoreHtmlArray1;
        // let tempArray1  = [];

        // tempArray1.push(this.state.button);
        // tempArray1.push(Choice);
        tempArray1.pop();
        tempArray1.push(Choice);
        // tempArray1.pop();
        this.setState({ addMoreHtmlArray1: tempArray1 })
    }


    AddButtonForQuestionType() {

        let allQuestionTypes = [
            {
                "id": "Multiple Choice",
                "name": "Multiple Choice"
            },
            {
                "id": "essay",
                "name": "Essay"
            },
        ];

        return (



            <div className="courseInfo">
                <div className="mainFormBox mainFormBox1" style={{ padding: '0px', marginLeft: '537px', border: '0px', width: '7px' }}>


                </div>
            </div>

        );
    }

    getQuestionEditor() {

        let bodyComponent = null;
        let header = "New Question";
        let headerId = "";
        let headerBody = "";
        let questionData = null;
        let array = [];

        if (this.state.showCreateQuestionDialogBox) {
            questionData = {
                subjects: [this.state.selectedSubject],
                category: this.state.selectedCategory,
                subcategory: this.state.selectedSubCategory,
                questionType: this.state.selectedQuestionType,

            };
            this.props.manageQuestions.cloneQuestionData = {};
            this.props.manageQuestions.questionData = {};
        }



        if (this.state.showDuplicateQuestionDialogBox) {
            headerId = "ID #" + this.props.manageQuestions.cloneQuestionData.questionID;
            header = "Duplicate Question:"
            headerBody = "Select the Subject, Category, and Subcategory below that you would like to duplicate this question to"
        }

        return (

            <Modal dialogClassName="questionEditor"
                show={this.state.showCreateQuestionDialogBox || this.state.showDuplicateQuestionDialogBox}
                id="createQuestion"
                backdrop="static"
                onHide={() => {

                    this.setState({ showClassAssignmentMessage1: true });
                }}
                bsSize="large" aria-labelledby="contained-modal-title-lg" ref="questionEditorDialog"
                container={this}
                >

                <Modal.Header closeButton>
                    <Modal.Title id="delete-activity-modal" style={{ marginLeft: "20px", paddingLeft: "15px" }}>
                        <div className="k" style={{ fontsize: '2px' }}>
                            {/* <h1>{header}</h1> */}
                            {
                                this.state.showDuplicateQuestionDialogBox &&
                                <div>
                                    <div>{headerId}
                                    </div>
                                    <br />
                                </div>
                            }
                            <b>{header}</b> {this.state.showDuplicateQuestionDialogBox && <p style={{ display: "inline" }}>{headerBody}</p>}
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <NewQuestion questionData={questionData} isClone={this.state.showDuplicateQuestionDialogBox} onSubmit={this.onSubmit} />
                </Modal.Body>
            </Modal>

        );
    }

    removeRecords(id) {

        let tempArray = this.state.addMoreHtmlArray;
        let index = tempArray.findIndex(x => x.props.id === id);
        tempArray.splice(id, 1);

        this.setState({ addMoreHtmlArray: tempArray })
    }

    AddMoreRecords() {

        let randomId = this.state.addMoreHtmlArray.length;


        let subjectsControl1 =

            <AutoCompleteGroup

                // label="Subject:"
                dropDownId="subjectDropDownId"
                placeholder="Select or enter Subject"
                field={"selectedSubject" + randomId}
                value={this.state['selectedSubject' + randomId]}
                options={this.state.allSubjects}
                onBlur={this.propertyChangedHandler1}
                //onChange={this.propertyChangedHandler1}
                id='subjectInfo'
                isDisabled={false}
            />

        let categoryControl1 =

            <AutoCompleteGroup
                // label="Category:"
                dropDownId="categoryDropDownId"
                placeholder="Select or enter Category"
                field={"selectedCategory" + randomId}
                value={this.state['selectedCategory' + randomId]}
                options={this.state.allCategories}
                onBlur={this.propertyChangedHandler1}
                // onChange={this.propertyChangedHandler1}
                id='categoryInfo'
                isDisabled={false}

            />

        let subCategoryControl1 =

            [<AutoCompleteGroup

                key={"selectedSubCategory" + randomId}
                // label="Subcategory:"
                dropDownId="subcategoryDropDownId"
                placeholder="Select or enter Subcategory"
                field={"selectedSubCategory" + randomId}
                value={this.state['selectedSubCategory' + randomId]}
                options={this.state.allSubCategories}
                onBlur={this.propertyChangedHandler1}
                // onChange={this.propertyChangedHandler1}
                id='subCategoryInfo'
                isDisabled={false}
            />,
            <Button
                key={randomId}
                onClick={
                    () => {

                        console.log("selected ID: ", randomId)
                        this.removeRecords(randomId);
                    }}

                style={{ padding: '4px', marginTop: '14px', fontSize: 'large', fontWeight: 'bold', marginBottom: '19px', width: '3%' }}
            >-</Button>]

        let finalHtml =
            <div id={randomId}>
                {subjectsControl1}
                {categoryControl1}
                {subCategoryControl1}
            </div>;
        let tempArray = this.state.addMoreHtmlArray;
        tempArray.push(finalHtml);
        this.setState({ addMoreHtmlArray: tempArray })
    }



    onSubmit(questionData) {
        if (this.state.showCreateQuestionDialogBox) {

            this.state.questionContent = "Your Question has been created"
        } else if (this.state.showDuplicateQuestionDialogBox) {

            this.state.questionContent = "Your Question has been duplicated"
        }
        else if (this.state.showModifyQuestionDialogBox) {

            this.state.questionContent = "Your Question has been updated"
        }

        this.setState({
            showCreateQuestionDialogBox: false,
            showDuplicateQuestionDialogBox: false,
            showModifyQuestionDialogBox: false,
            showClassAssignmentMessage2: true
        });
        const { dispatch } = this.props;
        dispatch(
            {
                type: MODIFY_QUESTION,
                questionData: null,
            },
            {
                type: CLONE_QUESTION,
                cloneQuestionData: null,
            });

        let subjects = questionData.subjects;
        let category = questionData.category;
        let subcategory = questionData.subcategory;


        setTimeout(() => {



            if (Array.isArray(subcategory)) {

                this.state.selectedSubject = subjects[subjects.length - 1]
                this.state.selectedCategory = category[category.length - 1]
                this.state.selectedSubCategory = subcategory[subcategory.length - 1]
            } else {

                this.state.selectedSubject = subjects[0];
                this.state.selectedCategory = category;
                this.state.selectedSubCategory = subcategory;

            }

            this.requestAllQuestions();
            this.getAllSubjects();

            //todo: subject was going missing but after adding this code it's working .. however apparently it shouldn't work.
            if (this.state.customSubjects) {

                this.state.customSubjects.forEach(subject => {

                    if (questionData.subjects.includes(subject)) {

                        if (!this.props.auth.user.subjects.includes(subject)) {

                            this.props.auth.user.subjects.push(subject);
                        }
                    }
                });
            }


            this.requestElasticSearch(this.state.selectedSubject, "") // subject -> categories request
            setTimeout(() => {

                this.requestElasticSearch(this.state.selectedSubject, this.state.selectedCategory) // subcategories request
            }, 1000)

        }, 3000);

    }

    onSubmitUpdate() {

        this.setState({
            showCreateQuestionDialogBox: false,
            showDuplicateQuestionDialogBox: false,
            showModifyQuestionDialogBox: false,
            showClassAssignmentMessagesubmit: true
        });

        // window.location.reload();
    }

    getModifyQuestionEditor() {

        let header = "Edit Question",
            headerId = "ID #" + this.props.manageQuestions.questionData.questionID;

        return (
            <Modal dialogClassName="questionEditor"
                show={this.state.showModifyQuestionDialogBox}
                backdrop="static"
                id="createQuestion"
                onHide={() => {

                    this.setState({ showClassAssignmentMessage1: true });
                }}
                container={this}
                bsSize="large" aria-labelledby="contained-modal-title-lg">
                <Modal.Header closeButton>
                    <Modal.Title id="delete-activity-modal" style={{ marginLeft: "20px", paddingLeft: "15px" }}>
                        <div>{headerId}</div>
                        <br />
                        {header}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <EditQuestion isModify="true" customSubjects={this.state.customSubjects} onSubmit={this.onSubmit} />
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
        );
    }


    getCustomQuery() {
        let isLPAdmin = this.props.auth.user.schoolName === "LearnerPal" && this.props.auth.user.userType.toLowerCase() === "admin";
        console.log('isLPAdmin', isLPAdmin);
        let visibility = '(public OR "' + this.props.auth.user.userName + '"';
        if (isLPAdmin) {
            visibility += " OR LPAdmin";
        }
        visibility += ')';


        let visibilityQuery = "visibleTo : " + visibility;
        if (this.props.auth.user.schoolName === "LearnerPal")
        visibilityQuery = "( " + visibilityQuery + " OR questionState2: (\"In Review\") OR questionState2: (\"Shared\"))";
        let query = visibilityQuery ;
        if(isLPAdmin){
            query='';
        }

        if (this.state.selectedSubject) {
            if(query.length > 0)
                query += ' AND subjects : ("' + this.state.selectedSubject + '")';
            else 
                query += 'subjects : ("' + this.state.selectedSubject + '")';
        }
        if (this.state.selectedCategory) {
          query += ' AND category: ("' + this.state.selectedCategory + '")';
        }
        if (this.state.selectedSubCategory) {
          query +=
            ' AND subcategory: ("' + this.state.selectedSubCategory + '")';
        }
        // let query = visibilityQuery + ' AND subjects : ("' + this.state.selectedSubject + '")' +
        // " AND category: (\"" + this.state.selectedCategory + "\")" + " AND subcategory: (\"" + this.state.selectedSubCategory + "\")";

        return query;
    }

    updatePopup() {

        return <Modal id="dialogCustomEntry"
            show={this.state.showUpdatePopup}
            style={{ zIndex: '10000' }}
            onHide={() => {

                this.setState({ showUpdatePopup: false, errors: {} });
            }}

            container={this}
            aria-labelledby="contained-modal-title">
            <Modal.Header closeButton>
                <Modal.Title id="delete-activity-modal">
                    {this.state.showEntryUpdateSubjectPopup ? "Update Subject" : null}
                    {this.state.showEntryUpdateCategoryPopup ? "Update Category" : null}
                    {this.state.showEntryUpdateSubCategoryPopup ? "Update Sub Category" : null}

                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="newone" style={{ marginTop: '-10px', marginBottom: '-15px' }}>
                    {this.state.showEntryUpdateSubjectPopup ?
                        <div style={{ marginBottom: "10px" }}>Updating subject: {this.state.selectedSubject} </div> : null}

                    {this.state.showEntryUpdateSubjectPopup && <TextFieldGroup
                        error={this.state.errors.updateSubject}
                        field="updateSubject"
                        value={this.state.updateSubject}
                        placeholder="Update Subject"
                        onChange={this.onAddCustomChanged.bind(this)}
                    />}

                    {this.state.showEntryUpdateCategoryPopup ?
                        <div style={{ marginBottom: "10px" }}>Updating '{this.state.selectedCategory}' category to subject: {this.state.selectedSubject}</div> : null}


                    {this.state.showEntryUpdateCategoryPopup && <TextFieldGroup
                        error={this.state.errors.updateCategory}
                        field="updateCategory"
                        value={this.state.updateCategory}
                        placeholder="Update Category"
                        onChange={this.onAddCustomChanged.bind(this)}
                    />}

                    {this.state.showEntryUpdateSubCategoryPopup ?
                        <div style={{ marginBottom: "10px" }}>Updating '{this.state.selectedSubCategory}' sub-category of category: {this.state.selectedCategory} to subject: {this.state.selectedSubject}</div> : null}

                    {this.state.showEntryUpdateSubCategoryPopup && <TextFieldGroup
                        error={this.state.errors.updateSubCategory}
                        field="updateSubCategory"
                        value={this.state.updateSubCategory}
                        placeholder="Update Sub-Category"
                        onChange={this.onAddCustomChanged.bind(this)}
                    />}

                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => { this.setState({ showUpdatePopup: false, errors: {} }); }} bsStyle='primary'>Cancel</Button>
                <Button onClick={this.onCustomSubjectCategSubCategAdded.bind(this, true)} bsStyle='primary'>Update</Button>

            </Modal.Footer>
        </Modal>;

    }

    customizedSubjectpopup() {
        const schoolTypes = [
            {id: 'Lower School', name: 'Lower School'},
            {id: 'Middle School', name: 'Middle School'},
            {id: 'Upper School', name: 'Upper School'},
            {id: 'Professional Learning', name: 'Professional Learning'}
        ]

        const setSchoolTypesHtml = (event) => {
            let keyword = '';
            if (event.target.selectedOptions && event.target.selectedOptions[0]) {
                keyword = event.target.selectedOptions[0].value;
            } else {
                keyword = event.nativeEvent.srcElement.value;
            }
            let selectedSchoolType = this.state.selectedSchoolType;
    
            for (let i = 0; i < selectedSchoolType.length; i++) {
                if (selectedSchoolType[i] === keyword) {
                    return;
                }
            }
            this.setState({ selectedSchoolType: this.state.selectedSchoolType.concat(keyword) });
        }

        const deleteTags = (event) =>{

            $('#subjects').prop('selectedIndex', 0);
            let tempTaggedSubjects = this.state.selectedSchoolType;
            for (let a = 0; a < tempTaggedSubjects.length; a++) {
                if (tempTaggedSubjects[a] === event.target.parentNode.firstElementChild.value) {
                    tempTaggedSubjects.splice(a, 1);
                }
            }
            this.setState({ selectedSchoolType: tempTaggedSubjects });
        }
    

        const allSchoolTypesHtml = []
        if (this.state.selectedSchoolType && this.state.selectedSchoolType.length > 0) {
            for (let i = 0; i < this.state.selectedSchoolType.length; i++) {
                allSchoolTypesHtml.push(<span key={this.state.selectedSchoolType[i]} className="keywordTag">
                    <input type="hidden" name="undefined[]" value={this.state.selectedSchoolType[i]} />{this.state.selectedSchoolType[i]}<span className="text-cross-icon" onClick={deleteTags} > ×</span>
                </span>);
            }
        }
        const isLPSchool = this.props.auth.user.schoolName == "LearnerPal";

        return <Modal id="dialogCustomEntry"
            show={this.state.showCustomizedSubjectPopup}
            style={{ zIndex: '10000' }}
            onHide={() => {

                this.setState({ showCustomizedSubjectPopup: false, errors: {} });
            }}

            container={this}
            aria-labelledby="contained-modal-title">
            <Modal.Header closeButton>
                <Modal.Title id="delete-activity-modal">
                    {this.state.showCustomizedEntryPopupForSubject ? "Add Subject" : null}
                    {this.state.showCustomizedEntryForCategory ? "Add Category" : null}
                    {this.state.showCustomizedEntryForSubCategory ? "Add Subcategory" : null}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="newone" style={{ marginTop: '-10px', marginBottom: '-15px' }}>

                    {this.state.showCustomizedEntryForCategory ?
                        <div style={{ marginBottom: "10px" }}>Adding category to subject: {this.state.selectedSubject}</div> : null}


                    {this.state.showCustomizedEntryForSubCategory ?
                        <div style={{ marginBottom: "10px" }}>
                            Adding Subcategory to Category: {this.state.selectedCategory}
                        </div>
                        : null}

                    {this.state.showCustomizedEntryPopupForSubject && <TextFieldGroup
                        error={this.state.errors.customSubject}
                        field="customSubject"
                        value={this.state.customSubject}
                        placeholder="Enter Subject"
                        onChange={this.onAddCustomChanged.bind(this)}
                    />}
                    {this.state.showCustomizedEntryPopupForSubject && isLPSchool && <div> 
                        <SelectFieldGroup
                            error={this.state.errors.schoolType}
                            dropDownId="schoolType"
                            // value={this.state.schoolType}
                            name='schoolType'
                            placeholder="Select School Type"
                            field="schoolType"
                            options={schoolTypes}
                            selectWidth={false}
                            onChange={setSchoolTypesHtml}
                            // onChange={this.onAddCustomChanged.bind(this)}
                            />
                            {allSchoolTypesHtml}
                        </div>}
                    {this.state.showCustomizedEntryForCategory && <TextFieldGroup
                        error={this.state.errors.customCategory}
                        field="customCategory"
                        value={this.state.customCategory}
                        placeholder="Enter Category"
                        onChange={this.onAddCustomChanged.bind(this)}
                    />}
                    {this.state.showCustomizedEntryForSubCategory && <TextFieldGroup
                        error={this.state.errors.customSubCategory}
                        field="customSubCategory"
                        value={this.state.customSubCategory}
                        placeholder="Enter Subcategory"
                        onChange={this.onAddCustomChanged.bind(this)}
                    />}

                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => { this.setState({ showCustomizedSubjectPopup: false, errors: {} }); }} bsStyle='primary'>Cancel</Button>
                <Button onClick={this.onCustomSubjectCategSubCategAdded.bind(this, true)} bsStyle='primary'>Add & Close</Button>
                <Button onClick={this.onCustomSubjectCategSubCategAdded.bind(this, false)} bsStyle='primary'>Add & Continue</Button>

            </Modal.Footer>
        </Modal>;

    }

    updateSubjectsTable(customSubject, isUpdate) {

        let schoolName = this.props.auth.user.schoolName ? this.props.auth.user.schoolName : 'trial';

        let body = {
            ownerName: this.props.auth.user.userName,
            schoolName: schoolName,
            subjectName: customSubject.name,
            categories: customSubject.categories,
        };

        if (customSubject.questionIds)
            body.questionIds = customSubject.questionIds;

        if (customSubject.subjectState)
            body.subjectState = customSubject.subjectState;

        if (customSubject.updatedSubject)
            body.updatedSubject = customSubject.updatedSubject;
        else if (customSubject.updatedCategory)
            body.updatedCategory = customSubject.updatedCategory;
        else if (customSubject.updatedSubCategory)
            body.updatedSubCategory = customSubject.updatedSubCategory;
    
        if (customSubject.tags && customSubject.tags.length > 0)
            body.tags = customSubject.tags;

        let method = isUpdate ? "PUT" : "POST";
        let context = this;
        if (customSubject.isSubject || customSubject.isCategory || customSubject.isSubCategory) {
            apiClient.invokeApi({}, '/subject', method, {}, body, "5")
                .then(function (result) {
                    this.handlePropertyChanged("selectedSubject", customSubject.name);
                    context.getAllSubjects();

                    if (customSubject.updatedCategory) {

                        setTimeout(() => {
                            context.showIndicator();
                            context.requestElasticSearch(context.state.selectedSubject, "", function (hello) {

                                context.hideIndicator();
                            });
                            context.setState({ selectedSubject: context.state.selectedSubject, selectedCategory: "", selectedSubCategory: "" })
                            this.handlePropertyChanged("selectedSubject", customSubject.name);
                        }, 2000);

                    } else if (customSubject.updatedSubCategory) {

                        setTimeout(() => {
                            context.showIndicator();
                            context.requestElasticSearch(context.state.selectedSubject, context.state.selectedCategory, function () {

                                context.hideIndicator();
                            });
                            let temp = context.state.selectedCategory
                            context.setState({ selectedSubject: context.state.selectedSubject, selectedCategory: context.state.selectedCategory, selectedSubCategory: "" })
                            this.handlePropertyChanged("selectedSubject", customSubject.name);

                            this.handlePropertyChanged("selectedCategory", temp);

                        }, 1000);
                    }
                }).catch(err => console.log(err))

        } else {

            let context = this;
            apiClient.invokeApi({}, '/subject', method, {}, body, "5")
                .then(function (result) {
                    let temp = context.state.selectedCategory;
                    context.handlePropertyChanged("selectedSubject", customSubject.name);

                    if (temp) {
                        context.handlePropertyChanged("selectedCategory", temp);
                    }
                    context.getAllSubjects();

                }).catch(err => console.log(err));
        }
    }


    updateSubjectInUserProfile(subject) {

        // step 1: get the subjects array ref in userSubjects
        let userSubjects = this.props.auth.user.subjects;

        // step 2: userSubjects is not defined, initialize it.
        if (!userSubjects)
            userSubjects = [];

        // step 3: add subject in userSubjects if it doesn't already exist.
        if (userSubjects.find(x => x === subject.name))
            return;

        userSubjects.push(subject.name);

        // step 4: add subject in userSubjects if it doesn't already exist.
        let context = this;

        let body = {
            userName: this.props.auth.user.userName,
            subjects: userSubjects
        };

        apiClient.invokeApi({}, '/user', 'PUT', {}, body, "2")
            .then(function (result) {

                localStorage.setItem('userObj', JSON.stringify(context.props.auth.user));

                const { dispatch } = context.props;
                dispatch({
                    type: SET_CURRENT_USER,
                    user: context.props.auth.user
                })
            }).catch(err => console.log(err))
    }

    removeSubjectInUserProfile(subject) {
        // step 1: get the subjects array ref in userSubjects
        let userSubjects = this.props.auth.user.subjects;

        // step 2: userSubjects is not defined, initialize it.
        if (!userSubjects)
            userSubjects = [];

        // step 3: remove subject from userSubjects if it already exists.

        let index = userSubjects.findIndex(x => x === subject.name)
        if (index > 0) {
            userSubjects.splice(index, 1); // remove one element

            let context = this;

            let body = {
                userName: this.props.auth.user.userName,
                subjects: userSubjects
            };

            apiClient.invokeApi({}, '/user', 'PUT', {}, body, "2")
                .then(function (result) {

                    localStorage.setItem('userObj', JSON.stringify(context.props.auth.user));

                    const { dispatch } = context.props;
                    dispatch({
                        type: SET_CURRENT_USER,
                        user: context.props.auth.user
                    })
                })
        }
    }


    removeFromSubjectsTable(customSubject) {

        let params = {
            ownerName: this.props.auth.user.userName,
            subjectName: customSubject.name,
        };

        let context = this;
        apiClient.invokeApi(params, '/subject/subjectName/{subjectName}/ownername/{ownerName}', "DELETE", {}, {}, "5")
            .then(function (result) {

                // console.log(result);
                context.getAllSubjects();
            })
    }

    getQuestionThenUpdate = (_this, customSubject, questionData) => {

        const questionIds = questionData.map(q => {
            const { id, subjects, category, subcategory } = q;
            return { id, subjects, category, subcategory };
        });

        // send updated customSubject to api
        _this.updateSubjectsTable({ ...customSubject, questionIds }, true);
    }

    onCustomSubjectCategSubCategAdded(closeAfterAdding) {

        let isValid = true;
        this.state.errors = {};
        const isLPSchool = this.props.auth.user.schoolName == "LearnerPal";

        let isCreateCustom = !this.state.showUpdatePopup;
        if (isCreateCustom) {
            if (this.state.showCustomizedEntryPopupForSubject && !this.state.customSubject) {
                isValid = false
                this.state.errors.customSubject = "Subject is required";
            }
            if (this.state.showCustomizedEntryPopupForSubject && isLPSchool && this.state.selectedSchoolType.length == 0 ) {
                isValid = false
                this.state.errors.schoolType = "School Type is required";
            }

            if (this.state.showCustomizedEntryForCategory && !this.state.customCategory) {
                isValid = false
                this.state.errors.customCategory = "Category is required";
            }

            if (this.state.showCustomizedEntryForSubCategory && !this.state.customSubCategory) {
                isValid = false
                this.state.errors.customSubCategory = "Subcategory is required";
            }

        }
        else {

            if (this.state.showEntryUpdateSubjectPopup && !this.state.updateSubject) {
                isValid = false
                this.state.errors.updateSubject = "Updated Subject name is required";
            }

            if (this.state.showEntryUpdateCategoryPopup && !this.state.updateCategory) {
                isValid = false
                this.state.errors.updateCategory = "Updated Category name is required";
            }

            if (this.state.showEntryUpdateSubCategoryPopup && !this.state.updateSubCategory) {
                isValid = false
                this.state.errors.updateSubCategory = "Updated SubCategory name is required";
            }

        }

        if (!isValid) {

            this.setState({ errors: this.state.errors });
            this.hideIndicator();
            return;
        }


        let isLPAdmin = this.props.auth.user.schoolName == "LearnerPal" && this.props.auth.user.userType.toLowerCase() === "admin";

        var { customEntryForID } = this.state;

        if (isCreateCustom) {
            if (this.state.showCustomizedEntryPopupForSubject) {

                // step 1: if custom subjects array is not initialized initialze with empty array.
                if (!this.state.customSubjects)
                    this.state.customSubjects = [];

                // step 2: check if subject already exists in the user?
                //if (this.state.customSubjects.find(x => x.name == this.state.customSubject)) {

                if (this.props.auth.user.subjects.find(x => x == this.state.customSubject)) {
                    this.state.errors.customSubject = "Subject already exists";
                    this.setState({ errors: this.state.errors });
                    return;
                }

                var subject = { owner: this.props.auth.user.userName, name: this.state.customSubject, categories: [] };
                if (isLPAdmin)
                    subject.subjectState = "Pending";
                if(this.state.selectedSchoolType ){
                    subject.tags = this.state.selectedSchoolType;
                }
                if(!isLPSchool){
                    subject.tags = [];
                }
                this.state.customSubjects.push(subject);

                this.updateSubjectInUserProfile(subject);
                this.updateSubjectsTable(subject, false);
            }


            // step 1: add subject not found in custom subjects
            if (this.state.showCustomizedEntryForCategory) {

                // step 1: if custom subjects array is not initialized initialze with empty array.
                if (!this.state.customSubjects)
                    this.state.customSubjects = [];

                //find subject
                let subject = this.state.customSubjects.find(x => x.name == this.state.selectedSubject);
                if (!subject) {

                    // if subject not found, create new subject

                    let category = { category: this.state.customCategory, subCategories: [] };

                    subject = {
                        owner: this.props.auth.user.userName,
                        name: this.state.selectedSubject,
                        subjectState: "Pending",
                        categories: [category],
                    }

                    if (isLPAdmin) {
                        subject.subjectState = "Pending";
                        category.state = "Pending";
                    }

                    //push subject with category
                    this.state.customSubjects.push(subject);

                    this.updateSubjectInUserProfile(subject);
                    this.updateSubjectsTable(subject, false);

                } else {

                    if (!subject.categories)
                        subject.categories = [];

                    // subject found
                    let categ = subject.categories.find(cat => cat.category === this.state.customCategory);
                    if (categ) {

                        this.state.errors.customCategory = "Category already exists";
                        this.setState({ errors: this.state.errors });
                        return;
                    }

                    let category = { category: this.state.customCategory, subCategories: [] };
                    if (isLPAdmin)
                        category.state = "Pending";

                    subject.categories.push(category);

                    this.updateSubjectsTable(subject, true);
                }
            }

            // step 1: add subject not found in custom subjects
            // step 2: add category if not
            if (this.state.showCustomizedEntryForSubCategory) {

                // step 1: if custom subjects array is not initialized initialze with empty array.
                if (!this.state.customSubjects)
                    this.state.customSubjects = [];

                //find subject
                let subject = this.state.customSubjects.find(x => x.name == this.state.selectedSubject);
                if (!subject) {
                    let category = { category: this.state.selectedCategory, subCategories: [{ subCategory: this.state.customSubCategory }] }
                    // if subject not found, create new subject
                    subject = {
                        owner: this.props.auth.user.userName,
                        name: this.state.selectedSubject,
                        categories: [category],
                    }

                    if (isLPAdmin) {
                        subject.subjectState = "Pending";
                        category.state = "Pending";
                    }


                    //push subject with category
                    this.state.customSubjects.push(subject);
                    this.updateSubjectInUserProfile(subject);
                    this.updateSubjectsTable(subject, false);

                } else {

                    // subject found
                    let categ = subject.categories.find(cat => cat.category === this.state.selectedCategory);
                    if (categ) {

                        let customSubCateg = categ.subCategories.find(subCateg => subCateg == this.state.customSubCategory)
                        if (customSubCateg) {

                            this.state.errors.customSubCategory = "Subcategory already exists";
                            this.setState({ errors: this.state.errors });
                            return;
                        }

                        let category = { subCategory: this.state.customSubCategory };
                        if (isLPAdmin)
                            category.state = "Pending";


                        categ.subCategories.push(category);
                    } else {

                        let category = { category: this.state.selectedCategory, subCategories: [] };
                        if (isLPAdmin)
                            category.state = "Pending";

                        subject.categories.push(category);

                        let subCategory = { subCategory: this.state.customSubCategory };
                        if (isLPAdmin)
                            subCategory.state = "Pending";

                        category.subCategories.push(subCategory);
                    }

                    this.updateSubjectsTable(subject, true);

                }

            }
        }
        else {

            this.showIndicator();
            if (this.state.showEntryUpdateSubjectPopup && this.state.updateSubject) {

                let subject = this.state.customSubjects.find(x => x.name === this.state.updateSubject);

                if (subject) {

                    isValid = false
                    this.state.errors.updateSubject = "Subject with same name already exists.";
                } else {

                    subject = this.state.customSubjects.find(x => x.name === this.state.selectedSubject);

                    let customSubject = {
                        name: this.state.updateSubject,
                        isSubject: true,
                        updatedSubject: this.state.updateSubject,
                        categories: subject.categories
                    };

                    // fecthing all questions
                    this.requestAllQuestions(customSubject, this.getQuestionThenUpdate);
                }

            }
            else if (this.state.showEntryUpdateCategoryPopup && this.state.updateCategory) {


                let subject = this.state.customSubjects.find(x => x.name === this.state.selectedSubject);
                let category = null;
                if (subject)
                    category = subject.categories.find(x => x.category === this.state.updateCategory);

                if (category) {

                    isValid = false
                    this.state.errors.updateCategory = "Category with the same name already exists.";
                } else {

                    subject = Object.assign({}, subject);

                    category = subject.categories.find(x => x.category === this.state.selectedCategory);
                    if (!category) {

                        isValid = false
                        this.state.errors.updateCategory = "Category does not exist.";
                    } else {

                        category.category = this.state.updateCategory;

                        let customSubject = {
                            isCategory: true,
                            name: subject.name,
                            categories: subject.categories,
                            updatedCategory: this.state.updateCategory,
                        }

                        let emptyCat = customSubject.categories.findIndex(x => x.category === "")
                        if (emptyCat > -1) {
                            customSubject.categories.splice(emptyCat, 1);
                        }

                        let emptySubCat = category.subCategories.findIndex(x => x.subCategory === "")
                        if (emptySubCat > -1) {
                            category.subCategories.splice(emptySubCat, 1);
                        }

                        // fecthing all questions
                        this.requestAllQuestions(customSubject, this.getQuestionThenUpdate);
                    }
                }

            }
            else if (this.state.showEntryUpdateSubCategoryPopup && this.state.updateSubCategory) {


                let subject = this.state.customSubjects.find(x => x.name === this.state.selectedSubject);

                let category = null, subCategory = null;

                if (subject)
                    category = subject.categories.find(x => x.category === this.state.selectedCategory);

                if (category)
                    subCategory = category.subCategories.find(x => x.subCategory === this.state.updateSubCategory);

                if (subCategory) {

                    isValid = false
                    this.state.errors.updateSubCategory = "Subcategory with the same name already exists";
                } else {


                    subCategory = category.subCategories.find(x => x.subCategory === this.state.selectedSubCategory);

                    if (!subCategory) {

                        isValid = false
                        this.state.errors.updateSubCategory = "Subcategory does not exist.";
                    }
                    else {

                        subCategory.subCategory = this.state.updateSubCategory;

                        let customSubject = {
                            isSubCategory: true,
                            name: subject.name,
                            categories: subject.categories,
                            updatedSubCategory: this.state.updateSubCategory
                        }

                        let empty = category.subCategories.findIndex(x => x.subCategory === "")
                        if (empty > -1) {
                            category.subCategories.splice(empty, 1);
                        }

                        // fecthing all questions
                        this.requestAllQuestions(customSubject, this.getQuestionThenUpdate);
                    }
                }
            }

        }

        if (!isValid) {
            this.setState({ errors: this.state.errors });
            this.hideIndicator();
            return;
        }

        // if (this.state.selectedSubject) {
        //     this.requestElasticSearch(this.state.selectedSubject, this.state.showCustomizedEntryForSubCategory ? this.state.selectedCategory : "");
        // }

        let updateState = { customSubject: "", customCategory: "", customSubCategory: "", updateSubject: "", updateCategory: "", updateSubCategory: "", errors: {} }
        if (closeAfterAdding) {
            updateState.showCustomizedSubjectPopup = false;
            updateState.showUpdatePopup = false;
        }

        this.setState(updateState);
    }


    onAddCustomChanged(e) {

        let that = this;
        let update = { [e.target.name]: e.target.value };

        this.setState(update);
    }


    informationPopup() {

        return <Modal id="dialogCustomEntry"
            show={this.state.information.showInformationPopup}
            style={{ zIndex: '10000' }}
            onHide={() => {

                this.setState({ information: { showInformationPopup: false } });
            }}

            container={this}
            aria-labelledby="contained-modal-title">
            <Modal.Header closeButton>
                <Modal.Title id="delete-activity-modal">
                    {this.state.information.header}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h2>{this.state.information.message}</h2>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => { this.setState({ information: { showInformationPopup: false } }); }} bsStyle='primary'>OK</Button>
            </Modal.Footer>
        </Modal>;

    }


    questionPopup() {

        return <Modal id="dialogCustomEntry"
            show={this.state.question.showQuestionPopup}
            style={{ zIndex: '10000' }}
            onHide={() => {

                this.setState({ question: { showQuestionPopup: false } });
            }}

            container={this}
            aria-labelledby="contained-modal-title">
            <Modal.Header closeButton>
                <Modal.Title id="delete-activity-modal">
                    {this.state.question.header}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h2>{this.state.question.message}</h2>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => {

                    this.state.question.execute(this.state.question);

                    this.setState({ question: { showQuestionPopup: false } });
                }} bsStyle='primary'>Yes</Button>
                <Button onClick={() => { this.setState({ question: { showQuestionPopup: false } }); }} bsStyle='primary'>No</Button>
            </Modal.Footer>
        </Modal>;

    }

    addCustomValues() {

        let subjects = [];
        let selectedSubjectObject = {};
        let { selectedSubject } = this.state;
        let { user } = this.props.auth;

        if (this.props.auth.user.schoolName === "LearnerPal") {

            for (let sub of this.state.customSubjects) {

                let subjectName = sub.name;

                if (subjectName === selectedSubject)
                    selectedSubjectObject = sub;

                if (subjects.find(x => x === subjectName)) // duplicate subject exists.
                    continue;

                subjects.push(subjectName)
            }
        } else {

            subjects = this.props.auth.user.subjects
            const subjectIndex = this.state.customSubjects.findIndex(sub => sub.name === selectedSubject)
            selectedSubjectObject = this.state.customSubjects[subjectIndex]

        }

        // this.state.customSubjects.forEach(x => {

        //     let subjectName = x.name;

        //     if (subjectName === selectedSubject)
        //         selectedSubjectObject = x;

        //     if (subjects.find(y => y == subjectName))
        //         return;

        //     if (!user.subjects.find(y => y == subjectName))
        //         return;

        //     subjects.push(subjectName);
        // });

        subjects.sort(function (a, b) {

            if (a > b) {

                return 1;
            } else if (b > a) {

                return -1;
            }

            return 0;
        });

        let isLPAdmin = user.schoolName == "LearnerPal" && user.userType.toLowerCase() === "admin";

        let states = [
            {
                id: "Pending",
                name: "Pending"
            },
            {
                id: "In Review",
                name: "In Review"
            },
            {
                id: "Publish",
                name: "Publish"
            },
        ]

        this.state.customSubjectState = "";
        this.state.customCategoryState = "";
        this.state.customSubCategoryState = "";

        if (selectedSubjectObject) {
            this.state.customSubjectState = selectedSubjectObject.subjectState;
            if (selectedSubjectObject.categories) {

                let category = selectedSubjectObject.categories.find(x => x.category == this.state.selectedCategory);
                if (category) {
                    this.state.customCategoryState = category.state;

                    let subCategory = category.subCategories.find(x => x.subCategory == this.state.selectedSubCategory);

                    this.state.customSubCategoryState = subCategory ? subCategory.state : "Pending";

                }
            }
        }

        let context = this;
        return (

            <div>

                <Breadcrumb
                    breadcrumbsData={
                        [
                            {
                                name: 'Question Editor', action: () => {
                                    this.getAllSubjects();

                                    this.setState({ addCustomSubjects: false, selectedSubject: "", selectedCategory: "", selectedSubCategory: "" })
                                }
                            },
                            {
                                name: 'Manage Subjects', action: () => {

                                }
                            }
                        ]
                    }
                />


                <h1 style={{ textAlign: 'center' }}>
                    <strong>Add or Edit Subject, Category, Subcategory</strong>
                </h1>

                <div className="mainFormBox" style={{ margin: '30px 6%' }}>
                    <div id="assignment-analysis" className="row">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">

                            <div>

                                <div className="col-md-4">

                                    <div>
                                        <h2 key="heading"> <b>Subjects</b></h2>
                                        <div className="actionBlockDiv ques" style={{
                                            marginTop: '-54px',
                                            transform: 'scale(0.7, 0.7)',
                                            WebkitTransform: 'scale(0.7, 0.7)',
                                            marginBottom: '0px',
                                            marginRight: '-5px'
                                        }}>
                                            <a className="actionButtonclass" style={{ margin: '0px' }}>
                                                <span style={{
                                                    zIndex: '99',
                                                    left: '-375px',
                                                    width: '350px',
                                                    top: '0px'
                                                }} className="WHSign" >
                                                    Select a subject to see the corresponding categories and subcategories or add a new subject
                                            </span></a>
                                        </div>
                                    </div>


                                    <div key="subjects" style={{ overflow: "auto", padding: "1px", border: "solid 1px gray", height: "300px", }}>
                                        {
                                            subjects.map((subject, id) => {
                                                let context = this;
                                                return (
                                                    <div key={"subject" + id} >

                                                        <span style={(context.state.selectedSubject == subject ? { backgroundColor: "#44a9f2", color: "white", padding: "5px", display: "block", cursor: "pointer" } : { padding: "5px", display: "block", cursor: "pointer" })}
                                                            onClick={() => {


                                                                context.handlePropertyChanged("selectedSubject", subject);
                                                                // context.requestElasticSearch(subject, "");
                                                                context.setState({ selectedSubject: subject, selectedCategory: "", selectedSubCategory: "" })
                                                            }}>{subject}</span>
                                                        <hr style={{ margin: "0px" }} />
                                                    </div>
                                                )
                                            })
                                        }

                                    </div>



                                    <Button style={{ marginLeft: "3px", marginTop: "3px" }} onClick={() => {
                                        this.setState({ showCustomizedSubjectPopup: true, showCustomizedEntryPopupForSubject: true, showCustomizedEntryForCategory: false, showCustomizedEntryForSubCategory: false });
                                    }} bsStyle='primary'>Add New Subject</Button>


                                    {
                                        this.state.selectedSubject && (isLPAdmin || (selectedSubjectObject.ownerName === user.userName)) ?
                                            <Button style={{ marginLeft: "3px", marginTop: "3px" }} onClick={() => {

                                                let index = context.state.customSubjects.findIndex(x => x.name == selectedSubjectObject.name)

                                                if (index >= 0) {

                                                    let filters = [];
                                                    let that = context;

                                                    if (that.state.selectedSubject)
                                                        filters.push({ terms: { subjects: [that.state.selectedSubject] } });

                                                    let elasticSearchQuery = new ElasticSearchQuery();
                                                    elasticSearchQuery.query(ElasticSearchTypes.Question + config.stage, ElasticSearchColumns.Question, that.props,
                                                        "", 10, 1, { createdAt: { order: "desc" } }, filters, function (results, totalRecords) {

                                                            if (totalRecords === 0) {




                                                                let question = {

                                                                    context: that,
                                                                    showQuestionPopup: true,
                                                                    header: "Delete Subject",
                                                                    message: "Do you want to delete Subject " + selectedSubjectObject.name + "?",
                                                                    selectedSubjectObject: selectedSubjectObject,

                                                                    execute: function (obj) {

                                                                        let that = obj.context;

                                                                        that.state.customSubjects.splice(index, 1);
                                                                        that.removeSubjectInUserProfile(obj.selectedSubjectObject);
                                                                        that.removeFromSubjectsTable(obj.selectedSubjectObject)
                                                                        that.setState({ allCategories: [], allSubCategories: [], selectedSubject: "", selectedCategory: "", selectedSubCategory: "" });

                                                                    }
                                                                }

                                                                that.setState({ question })

                                                                // that.state.customSubjects.splice(index, 1);
                                                                // that.removeSubjectInUserProfile(selectedSubjectObject);
                                                                // that.removeFromSubjectsTable(selectedSubjectObject)
                                                                // that.setState({ allCategories: [], allSubCategories: [], selectedSubject: "", selectedCategory: "", selectedSubCategory: "" });

                                                            } else {
                                                                let message = selectedSubjectObject.name + " cannot be deleted because it has " + totalRecords + " question(s)."
                                                                that.setState({ information: { showInformationPopup: true, message, header: "Delete Subject" } });
                                                            }
                                                        });

                                                }

                                            }} bsStyle='primary'>Remove</Button>
                                            :
                                            null
                                    }

                                    {/* {
                                        false && this.state.selectedSubject && (
                                            isLPAdmin || (selectedSubjectObject.ownerName === user.userName)) ?
                                            <Button style={{ marginLeft: "3px", marginTop: "3px" }} onClick={() => {
                                                this.setState({ showUpdatePopup: true, showEntryUpdateSubjectPopup: true, showEntryUpdateCategoryPopup: false, showEntryUpdateSubCategoryPopup: false });
                                                this.state.updateSubject = this.state.selectedSubject;
                                            }} bsStyle='success'>Update</Button>
                                            :
                                            null
                                    } */}

                                    {
                                        this.state.selectedSubject && isLPAdmin ?
                                            <SelectFieldGroup
                                                ref="stateControl"
                                                dropDownId="stateDropDownId"
                                                value={this.state.customSubjectState}
                                                field="customSubjectState"
                                                options={states}
                                                placeholder="Select State"
                                                onChange={(e) => {

                                                    let that = this;
                                                    let update = { [e.target.name]: e.target.value };
                                                    let propName = e.target.name;
                                                    let propValue = e.target.value;

                                                    // let subject = this.state.customSubjects.findIndex(x => x.subject == selectedSubjectObject.subject)

                                                    selectedSubjectObject.subjectState = propValue;

                                                    if (selectedSubjectObject.categories) {

                                                        selectedSubjectObject.categories.forEach(categ => {

                                                            categ.state = propValue;
                                                            if (categ.subCategories) {

                                                                categ.subCategories.forEach(subCateg => {

                                                                    subCateg.state = propValue;
                                                                })
                                                            }

                                                        })
                                                    }

                                                    selectedSubjectObject.isSubject = true;
                                                    selectedSubjectObject.customState = propValue;
                                                    this.updateSubjectsTable(selectedSubjectObject, true);


                                                }}
                                            />

                                            :
                                            null

                                    }



                                </div>
                                {
                                    this.state.allCategories ?
                                        <div className="col-md-4">
                                            {/* <h1 key="heading">Categories</h1> */}
                                            <div>
                                                <h2 key="heading" > <b>Categories</b></h2>

                                                <div className="actionBlockDiv ques" style={{
                                                    marginTop: '-54px',
                                                    transform: 'scale(0.7, 0.7)',
                                                    WebkitTransform: 'scale(0.7, 0.7)',
                                                    marginBottom: '0px',
                                                    marginRight: '-5px'
                                                }}>
                                                    <a className="actionButtonclass" style={{ margin: '0px' }}>
                                                        <span style={{
                                                            zIndex: '99',
                                                            left: '-375px',
                                                            width: '350px',
                                                            top: '0px'
                                                        }} className="WHSign" >
                                                            Select a category to see the corresponding subcategory or add a new category to an existing subject
                                                    </span></a>
                                                </div>
                                            </div>

                                            <div key="categories" style={{ overflow: "auto", padding: "1px", border: "solid 1px gray", height: "300px" }}>
                                                {
                                                    this.state.allCategories.map((categ, id) => {

                                                        let context = this;
                                                        return (
                                                            <div key={"categ" + id}>
                                                                <span style={(context.state.selectedCategory == categ.name ? { backgroundColor: "#44a9f2", color: "white", padding: "5px", display: "block", cursor: "pointer" } : { padding: "5px", display: "block", cursor: "pointer" })}
                                                                    onClick={() => {

                                                                        context.handlePropertyChanged("selectedCategory", categ.name);
                                                                        context.setState({ selectedCategory: categ.name, selectedSubCategory: "" })

                                                                    }}>{categ.name}</span>
                                                                <hr style={{ margin: "0px" }} />
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                            <Button style={{ marginLeft: "3px", marginTop: "3px" }} onClick={() => {
                                                this.setState({ showCustomizedSubjectPopup: true, showCustomizedEntryPopupForSubject: false, showCustomizedEntryForCategory: true, showCustomizedEntryForSubCategory: false });
                                            }} bsStyle='primary' disabled={this.state.selectedSubject ? false : true}>Add New Category</Button>

                                            {
                                                this.state.selectedCategory && (
                                                    isLPAdmin || (selectedSubjectObject.ownerName === user.userName)) ?
                                                    <Button style={{ marginLeft: "3px", marginTop: "3px" }} onClick={() => {

                                                        let index = selectedSubjectObject.categories.findIndex(x => x.category == this.state.selectedCategory);



                                                        if (index >= 0) {


                                                            let filters = [];
                                                            let that = this;

                                                            if (that.state.selectedSubject)
                                                                filters.push({ terms: { subjects: [that.state.selectedSubject] } });

                                                            if (that.state.selectedCategory)
                                                                filters.push({ terms: { category: [that.state.selectedCategory] } });

                                                            let elasticSearchQuery = new ElasticSearchQuery();
                                                            elasticSearchQuery.query(ElasticSearchTypes.Question + config.stage, ElasticSearchColumns.Question, that.props,
                                                                "", 10, 1, { createdAt: { order: "desc" } }, filters, function (results, totalRecords) {

                                                                    if (totalRecords === 0) {

                                                                        let question = {

                                                                            context: that,
                                                                            showQuestionPopup: true,
                                                                            header: "Delete Category",
                                                                            message: "Do you want to delete Category " + that.state.selectedCategory + "?",

                                                                            selectedSubjectObject: selectedSubjectObject,
                                                                            execute: function (obj) {

                                                                                obj.selectedSubjectObject.categories.splice(index, 1);
                                                                                obj.context.updateSubjectsTable(obj.selectedSubjectObject, true);
                                                                                obj.context.handlePropertyChanged("selectedSubject", obj.context.state.selectedSubject);
                                                                                obj.context.setState({ selectedCategory: "", selectedSubCategory: "" });

                                                                            }
                                                                        }

                                                                        that.setState({ question });

                                                                    } else {

                                                                        let message = that.state.selectedCategory + " category cannot be deleted."
                                                                        that.setState({ information: { showInformationPopup: true, message, header: "Delete Category" } })
                                                                    }
                                                                });

                                                        }

                                                    }} bsStyle='primary'>Remove</Button>
                                                    :
                                                    null
                                            }

                                            {
                                                this.state.selectedCategory && (
                                                    isLPAdmin || (selectedSubjectObject.ownerName === user.userName)) ?
                                                    <Button style={{ marginLeft: "3px", marginTop: "3px" }} onClick={() => {
                                                        this.setState({ showUpdatePopup: true, showEntryUpdateSubjectPopup: false, showEntryUpdateCategoryPopup: true, showEntryUpdateSubCategoryPopup: false });
                                                        this.state.updateCategory = this.state.selectedCategory;

                                                    }} bsStyle='primary'>Update</Button>
                                                    :
                                                    null
                                            }

                                            {
                                                this.state.selectedCategory && isLPAdmin ?
                                                    <SelectFieldGroup
                                                        ref="stateControl"
                                                        dropDownId="stateDropDownId"
                                                        value={this.state.customCategoryState}
                                                        field="customCategoryState"
                                                        options={states}
                                                        placeholder="Select State"
                                                        onChange={(e) => {

                                                            let that = this;
                                                            let update = { [e.target.name]: e.target.value };
                                                            let propName = e.target.name;
                                                            let propValue = e.target.value;

                                                            let canChangeSubject = false;
                                                            switch (propValue) {
                                                                case "Pending": {

                                                                    break;
                                                                }
                                                                case "In Review": {

                                                                    canChangeSubject = selectedSubjectObject.subjectState === "Pending";

                                                                    break;
                                                                }
                                                                case "Publish": {

                                                                    // if subject state is not publish, it shows category and subject is less accessible.
                                                                    canChangeSubject = selectedSubjectObject.subjectState != "Publish";

                                                                    break;
                                                                }
                                                            }

                                                            if (canChangeSubject)
                                                                selectedSubjectObject.subjectState = propValue;


                                                            let category = selectedSubjectObject.categories.find(x => x.category === this.state.selectedCategory);

                                                            if (category) {
                                                                category.state = propValue;

                                                                if (category.subCategories) {

                                                                    category.subCategories.forEach(subCateg => {

                                                                        subCateg.state = propValue;
                                                                    })
                                                                }
                                                            }

                                                            selectedSubjectObject.isCategory = true;
                                                            selectedSubjectObject.customState = propValue;

                                                            this.updateSubjectsTable(selectedSubjectObject, true);
                                                        }}
                                                    />
                                                    :
                                                    null
                                            }

                                        </div>
                                        : null
                                }
                                {
                                    this.state.allSubCategories ?
                                        <div className="col-md-4" >
                                            {/* <h1 key="heading">Subcategories</h1> */}

                                            <div >
                                                <h2 key="heading" > <b>Subcategories</b></h2>

                                                <div className="actionBlockDiv ques" style={{
                                                    marginTop: '-54px',
                                                    transform: 'scale(0.7, 0.7)',
                                                    WebkitTransform: 'scale(0.7, 0.7)',
                                                    marginBottom: '0px',
                                                    marginRight: '-5px'
                                                }}>
                                                    <a className="actionButtonclass" style={{ margin: '0px' }}>
                                                        <span style={{
                                                            zIndex: '99',
                                                            left: '-375px',
                                                            width: '350px',
                                                            top: '0px'
                                                        }} className="WHSign" >
                                                            Add a new subcategory to an existing category and subject
                                                    </span></a>
                                                </div>
                                            </div>

                                            <div key="subcategories" style={{ overflow: "auto", padding: "1px", border: "solid 1px gray", height: "300px" }}>
                                                {
                                                    this.state.allSubCategories.map((subCateg, id) => {

                                                        let context = this;
                                                        return (
                                                            <div key={"subCateg" + id}>
                                                                <span style={(context.state.selectedSubCategory == subCateg.name ? { backgroundColor: "#44a9f2", color: "white", padding: "5px", display: "block", cursor: "pointer" } : { padding: "5px", display: "block", cursor: "pointer" })}
                                                                    onClick={() => {

                                                                        // context.requestElasticSearch(context.state.selectedSubject, this.state.selectedCategory);
                                                                        context.handlePropertyChanged("selectedSubCategory", subCateg.name);

                                                                        context.setState({ selectedSubCategory: subCateg.name })
                                                                    }}>{subCateg.name}</span>

                                                                <hr style={{ margin: "0px" }} />
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>

                                            <Button style={{ marginLeft: "3px", marginTop: "3px" }} onClick={() => {
                                                this.setState({ showCustomizedSubjectPopup: true, showCustomizedEntryPopupForSubject: false, showCustomizedEntryForCategory: false, showCustomizedEntryForSubCategory: true });
                                            }} bsStyle='primary' disabled={this.state.selectedCategory ? false : true}>Add New Subcat</Button>

                                            {
                                                this.state.selectedSubCategory && (
                                                    isLPAdmin || (selectedSubjectObject.ownerName === user.userName)) ?
                                                    <Button style={{ marginLeft: "3px", marginTop: "3px" }} onClick={() => {

                                                        //selectedSubCategory

                                                        let category = selectedSubjectObject.categories.find(x => x.category == this.state.selectedCategory)

                                                        if (category) {
                                                            let index = category.subCategories.findIndex(x => x.subCategory == this.state.selectedSubCategory);
                                                            if (index >= 0) {


                                                                let filters = [];
                                                                let that = this;

                                                                if (that.state.selectedSubject)
                                                                    filters.push({ terms: { subjects: [that.state.selectedSubject] } });

                                                                if (that.state.selectedCategory)
                                                                    filters.push({ terms: { category: [that.state.selectedCategory] } });


                                                                if (that.state.selectedSubCategory)
                                                                    filters.push({ terms: { subcategory: [that.state.selectedSubCategory] } });

                                                                let elasticSearchQuery = new ElasticSearchQuery();
                                                                elasticSearchQuery.query(ElasticSearchTypes.Question + config.stage, ElasticSearchColumns.Question, that.props,
                                                                    "", 10, 1, { createdAt: { order: "desc" } }, filters, function (results, totalRecords) {


                                                                        if (totalRecords === 0) {

                                                                            let question = {

                                                                                context: that,
                                                                                category: category,
                                                                                showQuestionPopup: true,
                                                                                header: "Delete Subcategory",
                                                                                message: "Do you want to delete Subcategory " + that.state.selectedSubCategory + "?",
                                                                                selectedSubjectObject: selectedSubjectObject,

                                                                                execute: function (obj) {

                                                                                    let that = obj.context;

                                                                                    obj.category.subCategories.splice(index, 1);
                                                                                    that.updateSubjectsTable(obj.selectedSubjectObject, true);

                                                                                    that.handlePropertyChanged("selectedCategory", that.state.selectedCategory);


                                                                                    // that.requestElasticSearch(that.state.selectedSubject, that.state.selectedCategory);
                                                                                    that.setState({ selectedSubCategory: "" });
                                                                                }
                                                                            }

                                                                            that.setState({ question })

                                                                            // category.subCategories.splice(index, 1);
                                                                            // that.updateSubjectsTable(selectedSubjectObject, true);
                                                                            // that.requestElasticSearch(that.state.selectedSubject, that.state.selectedCategory);
                                                                            // that.setState({ selectedSubCategory: "" });

                                                                        } else {

                                                                            let message = that.state.selectedSubCategory + " Subcategory cannot be deleted."
                                                                            that.setState({ information: { showInformationPopup: true, message, header: "Delete Subcategory" } })
                                                                        }
                                                                    });

                                                            }
                                                        }

                                                    }} bsStyle='primary'>Remove</Button>
                                                    :
                                                    null
                                            }

                                            {
                                                this.state.selectedSubCategory && (
                                                    isLPAdmin || (selectedSubjectObject.ownerName === user.userName)) ?
                                                    <Button style={{ marginLeft: "3px", marginTop: "3px" }} onClick={() => {
                                                        this.setState({ showUpdatePopup: true, showEntryUpdateSubjectPopup: false, showEntryUpdateCategoryPopup: false, showEntryUpdateSubCategoryPopup: true });

                                                        //if(this.state.showEntryUpdateSubCategoryPopup) this.state.updateSubCategory = this.state.selectedSubCategory;
                                                        this.state.updateSubCategory = this.state.selectedSubCategory;

                                                    }} bsStyle='primary'>Update</Button>
                                                    :
                                                    null
                                            }

                                            {
                                                this.state.selectedSubCategory && isLPAdmin ?
                                                    <SelectFieldGroup
                                                        ref="stateControl"
                                                        dropDownId="stateDropDownId"
                                                        value={this.state.customSubCategoryState}
                                                        field="customSubCategoryState"
                                                        options={states}
                                                        placeholder="Select State"
                                                        onChange={(e) => {

                                                            let that = this;
                                                            let update = { [e.target.name]: e.target.value };
                                                            let propName = e.target.name;
                                                            let propValue = e.target.value;

                                                            // if parent is less accessible change the parent's to more visible too.

                                                            let canChangeSubject = false;
                                                            let canChangeCategory = false;


                                                            let category = selectedSubjectObject.categories.find(x => x.category === this.state.selectedCategory);

                                                            switch (propValue) {
                                                                case "Pending": {

                                                                    break;
                                                                }
                                                                case "In Review": {

                                                                    canChangeSubject = selectedSubjectObject.subjectState === "Pending";
                                                                    canChangeCategory = category.state === "Pending"

                                                                    break;
                                                                }
                                                                case "Publish": {

                                                                    // if category or subject state is not publish, it shows category and subject is less accessible.
                                                                    canChangeSubject = selectedSubjectObject.subjectState != "Publish";
                                                                    if (category)
                                                                        canChangeCategory = category.state != "Publish";
                                                                    break;
                                                                }
                                                            }

                                                            if (canChangeSubject)
                                                                selectedSubjectObject.subjectState = propValue;


                                                            if (category) {

                                                                if (canChangeCategory)
                                                                    category.state = propValue;

                                                                if (category.subCategories) {

                                                                    let subCateg = category.subCategories.find(x => x.subCategory === this.state.selectedSubCategory);
                                                                    if (subCateg)
                                                                        subCateg.state = propValue;
                                                                }

                                                            }

                                                            selectedSubjectObject.isSubCategory = true;
                                                            selectedSubjectObject.customState = propValue;
                                                            this.updateSubjectsTable(selectedSubjectObject, true);


                                                        }}
                                                    />
                                                    :
                                                    null

                                            }


                                        </div>
                                        : null
                                }

                                {this.state.information.showInformationPopup ? this.informationPopup() : null}
                                {this.state.question.showQuestionPopup ? this.questionPopup() : null}

                            </div>

                        </div>
                    </div>
                </div>

                {this.state.showCustomizedSubjectPopup ? this.customizedSubjectpopup() : null}
                {this.state.showUpdatePopup ? this.updatePopup() : null}

                <ProgressIndicator show={this.state.loading} />
            </div>

        )
    }

    render() {
        let context = this;

        this.props.manageQuestions.results.forEach(row => {
            if (row.questionType) {
                switch (row.questionType.toLowerCase()) {
                    case 'essay':
                        row.className = 'QuesWrite';
                        break;
                    case 'multiple choice':
                        row.className = 'QuesRead';
                        break;
                }
            }
            if (row.id === this.state.selectedRow.id) {
                row.className += " highlightRow";
            }
        });


        let viewQuestionDetailsPageView = null;
        let esAttr = this.props.elasticSearchAttributes.map.get('questions');


        /*view edit*/

        let maintenanceTableData = {
            emptyMessage: 'No records found.',
            showActionMenu: true,

            actions: [
                {
                    linkLabel: 'View', action: (questionsData, evt) => {
                        let that = this;
                        that.setState({ selectedRow: questionsData }, function () {
                            that.viewQuestion(questionsData);
                        });

                    },
                    isConditional: false,
                },
                {
                    linkLabel: 'Edit', action: (selectedRecord) => {
                        const { dispatch } = this.props;
                        let that = this;

                        // apiClient.invokeApi({ questionID: selectedRecord.id }, '/question/{questionID}', 'GET', {}, {}).then(function (result) {
                        apiClient.invokeApi({ questionid: selectedRecord.id, isAssessment: false }, '/getQuestionById/{questionid}/{isAssessment}', 'GET', {}, {}, 6).then(function (result) {
                            dispatch({
                                type: MODIFY_QUESTION,
                                questionData: result.data,
                            });

                            that.setState({ showModifyQuestionDialogBox: true });

                            //browserHistory.push('/questions/modify');

                            // browserHistory.replace({
                            //     pathname: '/questions',
                            //     state: {}
                            // });

                        }.bind(this)).catch(function (result) {

                            console.log("error occured while processing data from /question/{questionID} ");
                            console.log(result);
                        });

                    },
                    isConditional: true,
                    conditionExpression: (currentRecord) => {

                        // question owner
                        // lp admin
                        // lp in review questions should be visible to

                        let isLPAdmin = context.props.auth.user.schoolName === "LearnerPal" && context.props.auth.user.userType.toLowerCase() === "admin";
                        if (currentRecord.visibleTo[0] == context.props.auth.user.userName) {

                            if (currentRecord.questionState2 === "Published")
                                return false;

                            return true;

                        } else if (context.props.auth.user.schoolName === "LearnerPal") {

                            /// if LPAdmin .. he should be allowed to edit the question
                            if (isLPAdmin)
                                return true;

                            // if the user is LP School Teacher and question is in review then he should be able to edit the question.
                            else if (currentRecord.questionState2 === "In Review" || currentRecord.questionState2 === "Shared")
                                return true;

                        }

                        return false;
                    }
                },
                {
                    linkLabel: 'Duplicate', action: (selectedRecord, evt) => {
                        const { dispatch } = this.props;
                        let that = this;

                        // body = {
                        //     "questionID": question.questionID,
                        //     "question": question.question,
                        //     "questionType": question.questionType
                        // };
                        // apiClient.invokeApi({ questionID: selectedRecord.id }, '/question/{questionID}', 'GET', {}, {}).then(function (result) {
                        apiClient.invokeApi({ questionid: selectedRecord.id, isAssessment: false }, '/getQuestionById/{questionid}/{isAssessment}', 'GET', {}, {}, 6).then(function (result) {
                            dispatch({
                                type: CLONE_QUESTION,
                                cloneQuestionData: result.data,
                            });

                            that.setState({ showDuplicateQuestionDialogBox: true });
                            //browserHistory.push('/questions/new');
                        }.bind(this)).catch(function (result) {
                            console.log("error occured while processing data from /question/{questionID} ");
                            console.log(result);
                        });
                    },
                    isConditional: false,
                },

                // {
                //     linkLabel: 'Delete', action: (selectedRecord) => {
                //         this.setState({ showQuestionDeleteDialog: true, selectedRecord: selectedRecord });
                //     },
                //     isConditional: false,
                // },
            ],
            columns: [
                {
                    // name: 'question', type: 'text', label: 'Questions', options: {
                    name: '', type: 'text', label: 'Question', options: {
                        sortable: true, trimText: true, trimSize: 250, customDom: (record) => { // customDom is not applicable to array type attributes, ex: subjects in activity.
                            console.log(record.id);
                            let questionToDisplay = record.question ? record.question: '';
                            let htmlToReactParser = new Parser();
                            questionToDisplay = questionToDisplay.replace(/&nbsp;/g, " ");
                            questionToDisplay = questionToDisplay.replace(/<br>/g, " ");
                            questionToDisplay = questionToDisplay.replace(/<br\/>/g, " ");
                            questionToDisplay = questionToDisplay.replace('[[[LP_ANSWERS]]]', '');
                            questionToDisplay = questionToDisplay.replace(/<img .*?>/g, "");
                            questionToDisplay = questionToDisplay.replace(/<img .*?\/>/g, "");
                            questionToDisplay = questionToDisplay.replace(/<IMG .*?>/g, "");
                            questionToDisplay = questionToDisplay.replace(/<IMG .*?\/>/g, "");
                            questionToDisplay = questionToDisplay.replace(/<span .*?>/g, "");
                            questionToDisplay = questionToDisplay.replace(/<\/span>/g, "");
                            questionToDisplay = questionToDisplay.replace(/<strong>/g, "");
                            questionToDisplay = questionToDisplay.replace(/<\/strong>/g, "");
                            questionToDisplay = questionToDisplay.replace(/<div id=\"IFRAME_DIV\" .*?>/g, "");
                            questionToDisplay = questionToDisplay.replace(/<\/div>/g, "");
                            questionToDisplay = questionToDisplay.replace(/<h1>/g, "");
                            questionToDisplay = questionToDisplay.replace(/<\/h1>/g, "");
                            questionToDisplay = questionToDisplay.replace(/<h2.*?>/g, "");
                            questionToDisplay = questionToDisplay.replace(/<\/h2>/g, "");
                            questionToDisplay = questionToDisplay.replace(/<h5>/g, "");
                            questionToDisplay = questionToDisplay.replace(/<\/h5>/g, "");
                            questionToDisplay = questionToDisplay.replace(/<table .*?>/g, "");
                            questionToDisplay = questionToDisplay.replace(/<\/table>/g, "");
                            questionToDisplay = questionToDisplay.replace(/<tbody .*?>/g, "");
                            questionToDisplay = questionToDisplay.replace(/<\/tbody>/g, "");
                            questionToDisplay = questionToDisplay.replace(/<tr .*?>/g, "");
                            questionToDisplay = questionToDisplay.replace(/<\/tr>/g, "");
                            questionToDisplay = questionToDisplay.replace(/<td .*?>/g, "");
                            questionToDisplay = questionToDisplay.replace(/<\/td>/g, "");
                            questionToDisplay = questionToDisplay.replace(/<p.*?>/g, "");
                            questionToDisplay = questionToDisplay.replace(/<\/p>/g, "");

                            let startTagIndex = questionToDisplay.indexOf('<div id="IFRAME_DIV"');
                            if (startTagIndex >= 0) {
                                let endTagIndex = questionToDisplay.indexOf('>', startTagIndex)
                                questionToDisplay = questionToDisplay.substring(0, startTagIndex) + '<div id="IFRAME_DIV">' + questionToDisplay.substring(endTagIndex + 1);
                            }

                            questionToDisplay = questionToDisplay.replace(/<div id="IFRAME_DIV" style="maxheight: 300px; height: 300px; vertical-align: top; overflow: auto; border-style: solid; border-width: 1px; display: inline-block; width: 95%; margin: 2px ;">/g, "<div id=\"IFRAME_DIV\" />");
                            while (questionToDisplay.indexOf("size=\"4\"") >= 0) {
                                questionToDisplay = questionToDisplay.replace("size=\"4\"", "");
                            }
                            while (questionToDisplay.indexOf("size=\"3\"") >= 0) {
                                questionToDisplay = questionToDisplay.replace("size=\"3\"", "");
                            }
                            while (questionToDisplay.indexOf("size='4'") >= 0) {
                                questionToDisplay = questionToDisplay.replace("size='4'", "");
                            }
                            while (questionToDisplay.indexOf("size='3'") >= 0) {
                                questionToDisplay = questionToDisplay.replace("size='3'", "");
                            }
                            if(questionToDisplay.indexOf("autoplay") >= 0) {
                                questionToDisplay = questionToDisplay.replace(/autoplay/g, "");
                            }
                            questionToDisplay = questionToDisplay.trim();
                            let text = htmlToReactParser.parse('<span style={{fontSize: "14px !important"}}>' + questionToDisplay + '</span>');

                            return (

                                <td >
                                    <span className="typeIcon"> </span>
                                    <span className="contentTypeQues ">
                                        {/*<div>{record.id}*/}
                                        <div lang={this.props.auth.user.prefLanguage}>
                                            {questionToDisplay ? <h5 style={{ fontSize: '14px !important' }}>{text}</h5> : <h5 style={{ 'fontStyle': 'italic' }}>No preview available for the question. Click to view the question</h5>}</div>

                                        {/*<h4>{htmlToReactParser.parse(record.question.replace('</?span[^>]*>',''))}</h4></div>*/}


                                        <p><Rater rating={record.rating} interactive={false} /></p>
                                        <p style={{ fontSize: '2 px !important' }}>By <strong>{record.ownerName} | ID#{record.id}</strong></p>
                                    </span>
                                </td>

                            );
                        }
                    }
                },

            ],
            items: this.props.manageQuestions.results,
            pageable: true,
            rowClickAction: this.viewQuestionOnRowClick,
            totalRecords: this.props.manageQuestions.totalRecords,

            pagination: {
                // pageSize: esAttr && esAttr.pageSize ? esAttr.pageSize : 10,
                pageSize: esAttr && esAttr.pageSize ? esAttr.pageSize : 100,
                activePage: 1,
                showPageInput: true,
                showFirstButton: true,
                showLastButton: true,
                showNextButton: true,
                showPreviousButton: true,
                // firstButtonLabel: '<<',
                // lastButtonLabel: '>>',
                // previousButtonLabel: '<',
                // nextButtonLabel: '>',
                showPageInfo: true,
                showGotoInput: false,
                gotoInputLabel: 'Goto',
                pageLinkSize: 5
            }
        };
        let sideViewPanel = null;
        if (this.state.viewQuestionPanel) {
            let choicesText = "";
            let question = this.state.selectedRecord;
            let quesText = question.question;
            let showChoices = quesText.indexOf('LP_ANSWERS') < 0;
            if (question.choices && question.choices.length > 0 && question.answer) {
                for (let j = 1; j < question.choices.length + 1; j++) {
                    if (question.choices[j - 1].toLowerCase() === question.answer.toLowerCase()) {
                        choicesText += "<div class='fieldBox spacer'><input type='radio' class='css-checkbox css-label1 bc_info card_side' value='choice" + j + "' name='choice" + j + "' checked/><label htmlFor='choice" + j + "' class='css-label c-handler radGroup1' >" + j + ") &nbsp;</label>" + `<span class='text-fix' dir lang=${this.props.auth.user.prefLanguage} >${question.choices[j - 1]}</span>` + "</div>"
                    }
                    else {
                        choicesText += "<div class='fieldBox spacer'><input type='radio' class='css-checkbox css-label1 bc_info card_side' value='choice" + j + "' name='choice" + j + "' /><label htmlFor='choice" + j + "' class='css-label c-handler radGroup1' >" + j + ") &nbsp;</label>" + `<span class='text-fix' dir lang=${this.props.auth.user.prefLanguage} >${question.choices[j - 1]}</span>` + "</div>"
                    }
                }
            }
            quesText = quesText.replace('[[[LP_ANSWERS]]]', choicesText);
            let expln = question.explanation ? question.explanation : "";
            let htmlToReactParser = new Parser();

            let panel = <div className="innerQuestionBox NiceScroll" style={{ display: 'block' }}>
                <span className="cross" onClick={this.hideViewQuestionPage} />
                <h4>Question:</h4>
                <p lang={this.props.auth.user.prefLanguage} className="byForceLato17Question">{htmlToReactParser.parse(quesText)}</p>
                <div className="question-and-choice">
                    {showChoices &&
                        <p>{htmlToReactParser.parse(choicesText)}</p>
                    }
                </div>
                <h4>Hint</h4>
                <p lang={this.props.auth.user.prefLanguage} >{htmlToReactParser.parse(question.hint)}</p>
                <h4>Explanation:</h4>
                <p lang={this.props.auth.user.prefLanguage} className="byForceLato17Explaination">{htmlToReactParser.parse(expln.replace('autoplay', ''))}</p>
            </div>;

            sideViewPanel = <div className="topRowHaedingAnch">
                <span className="scrollBar" />
                {panel}
            </div>
        }
        if (this.state.viewQuestionDetailsPage) {
            let tempQuestionsHolder = this.state.selectedRecord;
            let choicesHtml = [];
            let quesText = "";
            let choicesText = "";
            let showChoices = true;
            if (tempQuestionsHolder && tempQuestionsHolder.question) {
                quesText = tempQuestionsHolder.question;
                showChoices = quesText.indexOf('LP_ANSWERS') < 0;
                if (tempQuestionsHolder.choices) {
                    for (let i = 1; i < tempQuestionsHolder.choices.length + 1; i++) {
                        if (tempQuestionsHolder.choices[i - 1].toLowerCase() === tempQuestionsHolder.answer.toLowerCase()) {
                            choicesText += "<div class='fieldBox'><input type='radio' class='css-checkbox css-label1 bc_info card_side' value='both' name='choice" + i + "' checked/><label htmlFor='choice" + i + "' class='css-label radGroup1' >" + i + ") &nbsp; " + tempQuestionsHolder.choices[i - 1] + "</label></div>"
                        } else {
                            choicesText += "<div class='fieldBox'><input type='radio' class='css-checkbox css-label1 bc_info card_side' value='both' name='choice" + i + "'/><label htmlFor='choice" + i + "' class='css-label radGroup1' >" + i + ") &nbsp; " + tempQuestionsHolder.choices[i - 1] + "</label></div>"
                        }
                    }
                }
                quesText = quesText.replace('[[[LP_ANSWERS]]]', choicesText);
            }
            let htmlToReactParser = new Parser();

            viewQuestionDetailsPageView =
                <div id="viewDetailsPage">
                    <Breadcrumb
                        breadcrumbsData={
                            [
                                {
                                    name: 'Manage Questions', action: () => {
                                        this.hideViewDetailPage();
                                    }
                                },
                                {
                                    name: 'View Details', action: () => {

                                    }
                                }
                            ]
                        }
                    />
                    <div className="container-fluid grey-background">
                        <div className="container-fluid questionMainBlock">
                            <div className="quesAndIcon">
                                <div className="col-md-12">
                                    <p>{htmlToReactParser.parse(quesText)}</p>
                                </div>
                                {showChoices &&
                                    <div className="col-md-12">
                                        {htmlToReactParser.parse(choicesText)}
                                    </div>
                                }
                                <div className="col-md-12">
                                    <h3>Explanation: </h3>
                                    <p>{htmlToReactParser.parse(tempQuestionsHolder.explanation.replace('autoplay', ''))}</p>
                                </div>
                                <div className="col-md-12">
                                    <h3>Hint</h3>
                                    <p>{htmlToReactParser.parse(tempQuestionsHolder.hint)}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>;
        }



        maintenanceTableData.actions.push({
            linkLabel: 'Delete', action: (selectedRecord) => {
                this.setState({ showQuestionDeleteDialog: true, selectedRecord: selectedRecord });
            },
            isConditional: true,
            conditionExpression: (currentRecord) => {

                if (context.props.auth.user.schoolName === "LearnerPal" && context.props.auth.user.userType.toLowerCase() === "admin") {

                    return true;
                } else if (currentRecord.ownerName4 == context.props.auth.user.userName) {

                    return true;
                }

                return false;
            }
        })

        if (context.props.auth.user.schoolName === "LearnerPal" && context.props.auth.user.userType.toLowerCase() === "admin") {

            maintenanceTableData.columns.push({
                name: 'questionState2', type: 'text', label: 'State', options: {
                    sortable: false, width: '10%', customDom: (record) => {

                        let questionState = record.questionState2;
                        if (!record.questionState2)
                            questionState = "Published";

                        return <td><h5 style={{ fontSize: '14px !important' }}>{questionState}</h5></td>;

                    }
                }
            });
        }

        const { screenWidth } = this.state;
        const isMobile = screenWidth < 768;
        let styleForMobileVIew = {};
        if (isMobile) {
            styleForMobileVIew = { "width": "100%", "height": "100%", "position": "fixed", "top": "50", "zIndex": "5" };
            if (this.state.viewQuestionPanel || this.viewURLPanel || this.viewPDFPanel || this.viewFlashcardsPanel || this.state.viewQuestionDetailsPage) {
                //hideHeading = true;
            }
        }


        let query = this.getCustomQuery();
        if (this.state.addCustomSubjects) {

            let customValuesControl = this.addCustomValues();

            return customValuesControl;
        }

        if (this.state.moveQuestions) {

            return <MoveQuestionsComponent />
        }

        let isLPAdmin = context.props.auth.user.schoolName === "LearnerPal" && context.props.auth.user.userType.toLowerCase() === "admin";

        return (
            <div>


                {this.state.isLandingPage ?
                    <div>
                        {
                            this.state.isActivityTypeLandingPage ?
                                <Breadcrumb
                                    breadcrumbsData={
                                        [
                                            {
                                                name: 'Question Editor', action: () => {
                                                    this.setState({ isActivityTypeLandingPage: false })
                                                }
                                            },
                                            {
                                                name: 'Create Questions', action: () => {

                                                }
                                            }
                                        ]
                                    }
                                />
                                :
                                <Heading
                                    showFilterButtons="false"
                                    heading="Question Editor"
                                />
                        }

                        <div className="col-md-2 hidden-xs hidden-sm"></div>
                        <div className="mainFormBox col-md-8 col-sm-12 col-xs-12" style={{ margin: '15px 0px 80px' }}>
                            {
                                !this.state.isActivityTypeLandingPage &&
                                <div className="row">
                                    <div className="col-md-12 col-sm-12 col-xs-12">
                                        <h1 style={{ textAlign: 'center', marginTop: '-5px' }}>
                                            <strong style={{ fontSize: 'x-large' }}>Question Management</strong>
                                        </h1>
                                        <div style={{ textAlign: 'center', fontStyle: 'italic' }}>
                                            <strong style={{ fontSize: '14px' }}>(Manage, add, edit questions to be used with your question activities)</strong>
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className="row">
                            </div>
                            {
                                !this.state.isActivityTypeLandingPage &&
                                <div>
                                    <div className="row">
                                        <div className="col-md-1 hidden-sm hidden-xs"></div>
                                        <div className="col-md-10 col-sm-12 col-xs-12">
                                            <div className="mainFormBox" style={{ minHeight: '90px', cursor: 'pointer' }} onClick={() => {
                                                this.setState({ isLandingPage: false });
                                            }}>

                                                <div className="" style={{
                                                    width: '0px',
                                                    position: 'relative',
                                                    marginRight: '70px',
                                                    marginTop: '-7px',
                                                    float: 'left'
                                                }}>
                                                    <img src={mq} style={{ height: '70px', marginLeft: '-15px' }} />
                                                </div>
                                                <strong style={{ fontSize: 'medium' }}>Manage Questions</strong>
                                                <div className="subdivision">View and edit existing questions or create new questions which can be used in question activies
                                          </div>

                                            </div>
                                        </div>
                                        <div className="col-md-1 hidden-sm hidden-xs"></div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-1 hidden-sm hidden-xs"></div>
                                        <div className="col-md-10 col-sm-12 col-xs-12">
                                            <div className="mainFormBox" style={{ minHeight: '90px', cursor: 'pointer' }} onClick={() => {
                                                this.setState({ isActivityTypeLandingPage: true });
                                            }}>
                                                <div className="" style={{
                                                    width: '0px',
                                                    position: 'relative',
                                                    marginRight: '70px',
                                                    float: 'left'
                                                }}>

                                                    <img src={cnq} style={{ height: '65px', marginTop: '-5px' }} />
                                                </div>
                                                <strong style={{ fontSize: 'medium' }}>Create New Questions</strong>
                                                <div className="subdivision">Create new questions which can be used within question activities
                                          </div>
                                            </div>
                                        </div>
                                        <div className="col-md-1 hidden-sm hidden-xs"></div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-1 hidden-sm hidden-xs"></div>
                                        <div className="col-md-10 col-sm-12 col-xs-12">
                                            <div className="mainFormBox" style={{ minHeight: '90px', cursor: 'pointer' }} onClick={() => {
                                                this.setState({ showBeforeImportQuestionDialog: true })
                                                //this.importExcelFile
                                            }
                                            }

                                            >
                                                <div className="" style={{
                                                    width: '0px',
                                                    position: 'relative',
                                                    marginRight: '70px',
                                                    float: 'left'
                                                }}>

                                                    <img src={importbutton} style={{ height: '65px', marginTop: '-17px' }} />
                                                </div>
                                                {/*<div style={{float:'left',marginTop: '-7px', marginRight:'8px',}}><span style={{color:'#55a4f0'}}><MaterialIcon icon='note_add' size={60}/></span></div>*/}
                                                <strong style={{ fontSize: 'medium' }}>Upload Questions</strong>
                                                <div className="subdivision">Upload Questions in bulk with LearnerPal&apos;s question upload template</div>
                                            </div>
                                        </div>
                                        <div className="col-md-1 hidden-sm hidden-xs"></div>
                                    </div>


                                    {this.state.showAlert &&
                                        <div className="row">
                                            {/* <div className="col-md-1 col-xs-3" /> */}
                                            <div className="col-md-10 col-xs-6  col-md-offset-1 col-xs-offset-3">
                                                <style>{"\
                                                        .alert {\
                                                            width: 54%;\
                                                        }\
                                                    "}</style>
                                                <Alert bsStyle={this.state.alertStyle}
                                                    onDismiss={this.handleAlertDismiss}>
                                                    {this.state.alertText}
                                                </Alert>
                                            </div>
                                            {/* <div className="col-md-1 col-xs-3" /> */}
                                        </div>
                                    }

                                    {this.state.showImportQuestionDialog && this.getImportDialog()}
                                    {this.state.showBeforeImportQuestionDialog && this.getBeforeImportDialog()}

                                    <div className="row">
                                        <div className="col-md-1 hidden-sm hidden-xs"></div>
                                        <div className="col-md-10 col-sm-12 col-xs-12">
                                            <div className="mainFormBox" style={{ minHeight: '90px', cursor: 'pointer' }} onClick={() => {
                                                this.setState({ addCustomSubjects: true })
                                            }}
                                            >

                                                <div className="" style={{
                                                    width: '0px',
                                                    position: 'relative',
                                                    marginRight: '70px',
                                                    float: 'left'
                                                }}>

                                                    <img src={customSubject} style={{ height: '58px', marginTop: '-17px' }} />
                                                </div>
                                                {/*<div style={{float:'left',marginTop: '-7px', marginRight:'8px',}}><span style={{color:'#55a4f0'}}><MaterialIcon icon='note_add' size={60}/></span></div>*/}
                                                <strong style={{ fontSize: 'medium' }}>Create New Subjects, Categories and Subcategories</strong>
                                                <div className="subdivision">Add new structures to organize your questions</div>
                                            </div>
                                        </div>
                                        <div className="col-md-1 hidden-sm hidden-xs"></div>
                                    </div>

                                    {
                                        isLPAdmin ?
                                            <div className="row">
                                                <div className="col-md-1 hidden-sm hidden-xs"></div>
                                                <div className="col-md-10 col-sm-12 col-xs-12">
                                                    <div className="mainFormBox" style={{ minHeight: '90px', cursor: 'pointer' }} onClick={() => {
                                                        this.setState({ moveQuestions: true })
                                                    }}
                                                    >

                                                        <div className="" style={{
                                                            width: '0px',
                                                            position: 'relative',
                                                            marginRight: '70px',
                                                            float: 'left'
                                                        }}>

                                                            <img src={questionMove} style={{ height: '58px', marginTop: '-17px' }} />
                                                        </div>
                                                        {/*<div style={{float:'left',marginTop: '-7px', marginRight:'8px',}}><span style={{color:'#55a4f0'}}><MaterialIcon icon='note_add' size={60}/></span></div>*/}
                                                        <strong style={{ fontSize: 'medium' }}>Move Questions</strong>
                                                        <div className="subdivision">Move your questions from one Subject, Category or Subcategory to another</div>
                                                    </div>
                                                </div>
                                                <div className="col-md-1 hidden-sm hidden-xs"></div>
                                            </div>

                                            :
                                            null
                                    }
                                </div>
                            }

                            {

                                this.state.isActivityTypeLandingPage &&

                                <div>

                                    <h1 style={{ textAlign: 'center' }}>
                                        <strong style={{ fontSize: 'large' }}>Select the type of question(s) you would like to create</strong>
                                    </h1>

                                    <div className="mainFormBox" style={{ margin: '30px 6%' }}>
                                        <div id="assignment-analysis" className="row">
                                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                <br />
                                                <p style={{ overflow: 'auto' }}>
                                                    <div className="row" style={{ margin: 'auto' }}>
                                                        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 report-box" style={{ cursor: 'pointer', textAlign: "center" }} onClick={() => {

                                                            context.setState({ selectedQuestionType: "Multiple Choice", }, function () {
                                                                context.setState({
                                                                    isLandingPage: false,
                                                                    showCreateQuestionDialogBox: true,
                                                                    backToLandingPage: true
                                                                })
                                                            });

                                                            // if( this.state.selectedQuestionType === "Multiple Choice") {
                                                            //     this.createQuestion();
                                                            //
                                                            // this.setState({showCreateQuestionDialogBox: true});
                                                            //     this.getQuestionFilters();
                                                            // }
                                                            // browserHistory.push('/questions');
                                                        }}>
                                                            <img src={mcqs} style={{ height: '95px', /*marginTop: '5px', marginLeft: ' 47px'*/ }} />
                                                            {/*<span style={{color:'#66af47'}}><MaterialIcon icon='assignment' size={48}/></span>*/}
                                                            <div>
                                                                <p>Multiple Choice Question</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 report-box" style={{ cursor: 'pointer', textAlign: "center" }} onClick={() => {

                                                            context.setState({ selectedQuestionType: "Essay" }, function () {
                                                                context.setState({
                                                                    isLandingPage: false,
                                                                    // isActivityTypeLandingPage: false,
                                                                    backToLandingPage: true,
                                                                    showCreateQuestionDialogBox: true
                                                                })
                                                            });

                                                        }}>
                                                            <img src={essayqs} style={{ height: '95px', /*marginTop: '5px', marginLeft: ' 47px'*/ }} />
                                                            <div>
                                                                <p /*style={{ marginLeft: '43px' }}*/>Essay Question</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 report-box" style={{ textAlign: "center" }} onClick={() => {

                                                            // ClevertapReact.event("Grade Tracker Report", {'userName' : context.props.auth.user.userName});
                                                            context.setState({
                                                                showSubjectDialog: true
                                                            });

                                                        }}>
                                                            <img src={response} style={{ height: '95px', /*marginTop: '7px', marginLeft: ' 47px'*/ }} />

                                                            <div>
                                                                <p /*style={{ marginLeft: '43px' }}*/>Free Response</p>

                                                            </div>

                                                        </div>
                                                        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 report-box" style={{ textAlign: "center" }} onClick={() => {
                                                            // ClevertapReact.event("Grade Tracker Report", {'userName' : context.props.auth.user.userName});
                                                            context.setState({
                                                                showSubjectDialog: true
                                                            });
                                                        }}>
                                                            <div>
                                                                <img src={truefalse} style={{ height: '95px', /*marginTop: '5px', marginLeft: ' 47px'*/ }} />
                                                            </div>
                                                            <div>
                                                                <p /*style={{ marginLeft: '46px' }}*/>True or False</p>
                                                            </div>

                                                        </div>
                                                        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 report-box" style={{ textAlign: "center" }} onClick={() => {
                                                            // ClevertapReact.event("Grade Tracker Report", {'userName' : context.props.auth.user.userName});
                                                            context.setState({
                                                                showSubjectDialog: true
                                                            });
                                                        }}>
                                                            <div>
                                                                <img src={matching} style={{ height: '95px', /*marginTop: '5px', marginLeft: ' 47px'*/ }} />
                                                            </div>

                                                            <p /*style={{ marginLeft: '65px' }}*/>Matching</p>
                                                        </div>
                                                    </div>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            }

                        </div>
                        <div className="col-md-2 hidden-xs hidden-sm"></div>

                    </div>

                    :

                    <div>
                        <div>{viewQuestionDetailsPageView}</div>
                        <Heading
                            isHideHeading={this.state.viewQuestionDetailsPage}
                            allButtons={[
                                <button type="button" className="btn btn-primary crtBtn" onClick={this.createQuestion}
                                    key="CreateButton"><span> </span> Create New</button>
                            ]}
                            isCreateButton={false}
                            heading="Manage Questions"
                            //isShowButton={true}
                            isSearch={isLPAdmin ? true : false}
                            actionName={RECEIVED_QUESTIONS_RESULTS}
                            screenNameForElasticSearch={ElasticSearchTypes.Question}
                            allColumnsForElasticSearch={ElasticSearchColumns.Question}
                            location="questions"

                            customQuery={query}


                            breadcrumbsData={
                                [
                                    {
                                        name: 'Question Editor', action: () => {
                                            window.location.reload();
                                            // this.setState({isLandingPage: true});
                                        }
                                    },
                                    {
                                        name: 'Manage Questions', action: () => {
                                        }
                                    }
                                ]
                            }


                        />
                        <div id="tableFiltersAndSideView">
                            <div className="container-fluid noPad listMainBoxWrapper">
                                {((this.state.selectedSubCategory && this.props.auth.user.schoolName === "LearnerPal") || isLPAdmin) && <Filter
                                    customQuery={query}
                                    actionName={RECEIVED_QUESTIONS_RESULTS}
                                    viewActivityCssClass={this.filterComponentCssClassName}
                                    clearFilters={'manageQuestions.clearFilters'}
                                    setInstance={(instance)=> { this.child = instance; }}
                                    filterColumns={[
                                        // { name: 'subjects', type: 'array', label: 'Subjects', dependency: 'category' },
                                        // { name: 'category', type: 'text', label: 'Category', dependency: 'subcategory', dependent: true, refreshFilter: true },
                                        // { name: 'subcategory', type: 'text', label: 'Subcategory', dependent: true },
                                        { name: 'questionType', type: 'text', label: 'Question Type', sort: 'desc' },
                                        { name: 'ownerName', type: 'text', label: 'Owner Name', sort: 'desc' },
                                        { name: 'questionState2', type: 'text', label: 'Question State', sort: 'desc' }

                                    ]}
                                    screenNameForElasticSearch={ElasticSearchTypes.Question}
                                    allColumnsForElasticSearch={ElasticSearchColumns.Question}
                                    location="questions"
                                />}
                                {(this.state.selectedSubCategory && this.props.auth.user.schoolName !== "LearnerPal") && <Filter
                                    customQuery={query}
                                    setInstance={(instance)=> { this.child = instance; }}
                                    actionName={RECEIVED_QUESTIONS_RESULTS}
                                    viewActivityCssClass={this.filterComponentCssClassName}
                                    clearFilters={'manageQuestions.clearFilters'}
                                    filterColumns={[
                                        // { name: 'subjects', type: 'array', label: 'Subjects', dependency: 'category' },
                                        // { name: 'category', type: 'text', label: 'Category', dependency: 'subcategory', dependent: true, refreshFilter: true },
                                        // { name: 'subcategory', type: 'text', label: 'Subcategory', dependent: true },
                                        { name: 'questionType', type: 'text', label: 'Question Type', sort: 'desc' },
                                        { name: 'ownerName', type: 'text', label: 'Owner Name', sort: 'desc' }

                                    ]}
                                    screenNameForElasticSearch={ElasticSearchTypes.Question}
                                    allColumnsForElasticSearch={ElasticSearchColumns.Question}
                                    location="questions"
                                />}
                                <div className={this.tableCssClassName}>

                                    <div>
                                        {/* <span onMouseOver={(event) => { event.target.firstElementChild.src = exportIconHover; }} onMouseOut={(event) => { event.target.firstElementChild.src = exportIcon; }}
                                     className="detailPrintForReport" data-toggle="tooltip" title="Export questions to excel" data-placement="bottom" data-original-title="Print"
                                     onClick={this.exportQuestionsInExcel}>
                                     <img src={exportIcon} style={{ width: '43px', marginTop: '10px', marginLeft: '10px' }} />
                                     </span>
                                     <span onMouseOver={(event) => { event.target.firstElementChild.src = importIconHover; }} onMouseOut={(event) => { event.target.firstElementChild.src = importIcon; }}
                                     className="detailPrintForReport" data-toggle="tooltip" title="Import questions excel" data-placement="bottom" data-original-title="Print"
                                     onClick={this.importExcelFile}>
                                     <img src={importIcon} style={{ width: '43px', marginTop: '10px', marginLeft: '10px' }} />
                                     </span> */}

                                        {this.getQuestionFilters()}




                                    </div>



                                    <div className="listToBesslide NiceScroll">




                                        <div className="blockForlist ">



                                            <MaintenanceTable
                                                location="questions"
                                                screenNameForElasticSearch={ElasticSearchTypes.Question}
                                                allColumnsForElasticSearch={ElasticSearchColumns.Question}
                                                actionName={RECEIVED_QUESTIONS_RESULTS}
                                                options={maintenanceTableData}
                                                sortAction={{
                                                    action: { RECEIVED_QUESTIONS_RESULTS },
                                                    attributes: {
                                                        skipAlterForAllRecords: true
                                                    },
                                                    dataPath: 'data'
                                                }}
                                            />
                                            {/*{this.AddButtonForQuestionType()}*/}


                                            {/*{this.customizedSubjectpopup()}*/}

                                            <QuestionsMaintenanceTableMobile items={this.props.manageQuestions.results}
                                                emptyMessage={maintenanceTableData.emptyMessage}
                                                columns={maintenanceTableData.columns}
                                                viewQuestionMobile={this.viewQuestion}
                                            />


                                            <ReactScrollPagination
                                                fetchFunc={this.getNextPage}
                                            />

                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className={this.viewActivityCssClassName} id='sidePanelContainer'
                                style={styleForMobileVIew}>
                                {sideViewPanel}
                            </div>
                        </div>
                        {this.state.showAlert &&
                            <div className="row">
                                <div className="col-md-3 col-xs-3" />
                                <div className="col-md-6 col-xs-3">
                                    <Alert bsStyle={this.state.alertStyle}
                                        onDismiss={this.handleAlertDismiss}>
                                        {this.state.alertText}
                                    </Alert>
                                </div>
                                <div className="col-md-3 col-xs-3" />
                            </div>
                        }
                        {this.state.showHintDialog && this.getHintDialog()}
                        {this.state.showQuestionDeleteDialog && this.getDeleteDialog()}


                        {this.state.showCreateQuestionDialogBox && this.getQuestionEditor()}
                        {this.state.showModifyQuestionDialogBox && this.getModifyQuestionEditor()}
                        {/* {this.state.showClassAssignmentMessage && this.dialogueBox()} */}

                        {this.state.showClassAssignmentMessage1 && this.dialogueBoxClosingEditor()}
                        {this.state.showClassAssignmentMessage2 && this.dialogueBoxCreateQuestion()}
                        {this.state.showClassAssignmentMessagesubmit && this.UpdateQuestiondialogueBox()}

                        {this.state.showDuplicateQuestionDialogBox && this.getQuestionEditor()}
                        {/* {this.state.showClassAssignmentMessage101 && this.customizedSubjectpopup()} */}

                        {this.state.showQuestionStateChangedDialog && this.getQuestionStateChangedDialog()}


                    </div>}
                <ProgressIndicator show={this.state.loading} />
            </div>
        );
    }
}


function mapStateToProps(state) {
    return { ...state };
}

export default connect(mapStateToProps)(ManageQuestions);
